import React, { Component } from "react";
import { TextField, Tooltip } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import TimeField from "react-simple-timefield";
import {
  createAssessment,
  purchaseAddOns,
  getAddOnsList,
} from "../../services";
import {
  startLoader,
  stopLoader,
  setSelectedPlan,
} from "../../store/reducers/generalActions";
import MSG, { notifyError, notifySuccess } from "../../utils/Helper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./AddOnsCheckoutForm";
import textData from "./textData";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class TakeAssessment extends Component {
  state = {
    show: false,
    currentContentId: "",
    currentTopicName: "",
    time: "00:00",
    timerSeconds: 0,
    showTimeError: false,
    showTopicError: false,
    showLoader: false,
    stripePromise: null,
    stripeClientSecret: "",
    showStripeForm: false,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    showAddOn: false,
    AddOnText: "",
    AddOnCTAText: "",
    activatePlan: [],
  };

  // componentDidMount = () => {
  //   this.props.dispatch(getAddOnsList());
  // };

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
      currentContentId: nextProps.content_id,
      currentTopicName: nextProps.topic_name,
    });
  }

  handleTopicChange = (event) => {
    const value = event.target.value;
    this.setState({ currentTopicName: value, showTopicError: false });
  };

  //SWEET ALERT
  onAlertConfirm = () => {
    if (!this.state.currentTopicName.trim()) {
      this.setState({ showTopicError: true });
    } else if (this.state.timerSeconds < 1) {
      this.setState({ showTimeError: true });
    } else {
      this.props.callBack(this.state.currentTopicName, false);
      this.setState({ show: false }, () => this.handleRetake(true));
    }
  };

  onAlertCancel = () => {
    this.props.callBack(this.state.currentTopicName, false);
    this.setState({ show: false });
  };

  onAlertSkip = () => {
    if (!this.state.currentTopicName.trim()) {
      this.setState({ showTopicError: true });
    } else {
      this.props.callBack(this.state.currentTopicName, false);
      this.setState({ show: false }, () => this.handleRetake(false));
    }
  };

  assessmentTimerHandler = (event, value) => {
    const newTime = value.replace(/-/g, ":");
    const time = newTime.substr(0, 5);
    const seconds = time.split(":");
    const timerSeconds = Number(seconds[0]) * 60 * 60 + Number(seconds[1]) * 60;
    this.setState({ time, timerSeconds, showTimeError: false });
  };

  handleRetake = async (isTimer) => {
    this.props.dispatch(startLoader());
    const content_id = this.state.currentContentId;
    try {
      const req = {
        content_id: content_id,
        duration: isTimer ? this.state.timerSeconds : 0,
        topic: this.state.currentTopicName,
      };

      const { data: res } = await createAssessment(req);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.push({
          pathname: "/self-assessment",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
        if (this.props.user.plan_details.active_subscription) {
          let active_plan = this.props.user.plan_details.plan_name;
          // 1099 code means purchase add ons to use this feature.
          if (res.code == 1099) {
            this.setState({
              showAddOn: true,
              activatePlan:
                this.props.addOnsList["Self-Assessment"][active_plan],
              AddOnText: textData.SelfAssessmentAddOnText.replace(
                "{price}",
                this.props.addOnsList["Self-Assessment"][active_plan]
                  .currency_symbol +
                  this.props.addOnsList["Self-Assessment"][active_plan].price
              ),
              AddOnCTAText: textData.AddOnCTAText3,
            });
          }
        }
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  displayStripe = async (plan) => {
    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    try {
      let req = {
        add_on_id: plan.plan_id,
        // team_size: this.state.team_size,
      };

      const { data: res } = await purchaseAddOns(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
          // price_usd: plan.price,
          // plan_name: plan.plan_name,
          // currency:
          //   this.state.plans["Monthly"][this.state.questionPaperCountSolo][
          //     "currency"
          //   ],
          // team_size: this.state.team_size,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showAddOn: false,
          });
          // this.props.dispatch(
          //   showReferral({
          //     title: "Want to extend your plan for free?",
          //     content:
          //       "Invite your friends and get 7 days of Premium Plan added",
          //   })
          // );
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  render() {
    return (
      <>
        <SweetAlert
          customClass="sweet-alert-assessment"
          show={this.state.show}
          title={
            <div className="swal-title">
              <h2 style={{ marginBottom: "-8px" }}>
                Start your self-assessment.
              </h2>
              <span
                style={{ fontSize: "14px", color: "grey", fontWeight: "400" }}
              >
                Set a time limit and finish your test within the entered time
              </span>
              <hr />
              <div className="position-relative mb-4 d-flex flex-column justify-content-center">
                <input
                  className="subset-topic w-13 h1-8"
                  type="text"
                  name="topic"
                  value={this.state.currentTopicName}
                  onChange={this.handleTopicChange}
                  id="titleofTest"
                  aria-describedby="textHelp"
                  placeholder="Enter Topic"
                />
                {this.state.showTopicError && (
                  <span
                    style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                  >
                    Please enter a topic name!
                  </span>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center">
                <TimeField
                  value={this.state.time}
                  onChange={this.assessmentTimerHandler}
                  style={{ alignSelf: "center" }}
                  input={
                    <TextField
                      label="HH:MM"
                      value={this.state.time}
                      variant="outlined"
                      className="format-time"
                    />
                  }
                />
                {this.state.showTimeError && (
                  <span
                    style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                  >
                    Please enter a time limit!
                  </span>
                )}
              </div>
            </div>
          }
          customButtons={
            <React.Fragment>
              <button
                className="btn sweet-alert-close-btn"
                onClick={this.onAlertCancel}
              >
                X
              </button>
              <div
                className="d-flex flex-column"
                style={{ marginTop: "-0.5rem" }}
              >
                <button
                  className="btn btn-success sweet-alert-confirm-btn  w-13 h1-8"
                  onClick={this.onAlertConfirm}
                >
                  Begin Self Assessment
                </button>
                <a
                  href="#"
                  className="link-primary"
                  style={{ marginTop: "0.5rem" }}
                  onClick={this.onAlertSkip}
                >
                  Skip Time Limit
                </a>
              </div>
            </React.Fragment>
          }
          onEscapeKey={() =>
            this.setState({ show: false }, () =>
              this.props.callBack(this.state.currentTopicName, false)
            )
          }
          onOutsideClick={() =>
            this.setState({ show: false }, () =>
              this.props.callBack(this.state.currentTopicName, false)
            )
          }
        />
        <SweetAlert
          show={this.state.showAddOn}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Purchase Plan"
          confirmBtnText={`Proceed`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showAddOn: false })}
          onOutsideClick={() => this.setState({ showAddOn: false })}
          onCancel={() => this.setState({ showAddOn: false })}
        >
          <div style={{ padding: "20px" }}>
            <h4>Upgrade to access Premium add ons</h4>
            <div className="login--form">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: this.state.AddOnText }}
              ></div>

              <div>
                <button
                  onClick={() => this.displayStripe(this.state.activatePlan)}
                  type="button"
                  role="button"
                  className="preview--paper--btn"
                  style={{
                    width: "max-content",
                  }}
                  disabled={this.state.showLoader}
                >
                  {this.state.AddOnCTAText}
                </button>
              </div>
              {this.state.showLoader ? (
                <div className="spinner-border mt-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
  };
};

export default connect(mapStateToProps)(withRouter(TakeAssessment));

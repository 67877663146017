const textData = {
  FreeAddOnText: `<div style='padding: 1% 0'; font-size:"14px";>
                <p> Your 7-day free trial has ended. </p>
            </div>`,
  FreeAddOnSubText: `<span style="color: #dcdcdc !important;font-size: x-small;">
            Get lifetime access to creating questions in a snap only at {price}
      </span>`,
  VideoAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Selected Feature Is An Add-On 💎 </p></div>`,
  UploadDocumentAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Selected Feature Is An Add-On 💎 </p></div>`,
  DownloadDocumentAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Uh Oh! You’ve reached your daily limit.</p>
            <p>Get Unlimited Downloads @ Just {price}/month </p></div>`,
  SelfAssessmentAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Uh Oh! You’ve reached your daily limit.</p>
        <p>Get Unlimited Access @ Just {price}/month </p></div>`,
  GroupAssessmentAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Selected Feature Is An Add-On 💎 </p></div>`,
  QestionPaperAddOnText: `<div style='padding: 1% 0'; font-size:"14px";><p>Uh Oh! You’ve reached your limit.</p>
        <p>Have more question papers to generate? </p></div>`,
  AddOnCTAText: "Upgrade To PrepAI Premium @ {price}",
  AddOnCTAText2: "Access @ {price}/month",
  AddOnCTAText3: "Top-Up",
  AddOnCTAText4: "Upgrade to Premium",
};

export default textData;

import React, { Component } from "react";
import { connect } from "react-redux";
import { activateAddOns } from "../../services";
import {
  startLoader,
  stopLoader,
  setSelectedPlan,
  showReferral,
} from "../../store/reducers/generalActions";
import { Visa, Mastercard, Elo } from "react-pay-icons";
import MSG, { notifySuccess, notifyError } from "../../utils/Helper";
import { PaymentElement } from "@stripe/react-stripe-js";

class CheckoutForm extends Component {
  state = {
    showLoader: false,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      showLoader: !this.state.showLoader,
    });
    const { stripe, elements } = this.props;
    if (!stripe) {
      return false;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        return_url: "",
      },
      redirect: "if_required",
    });

    console.log("error", error);

    // const { error } = await stripe.confirmSetup({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   // confirmParams: {
    //   //   return_url: 'https://example.com/account/payments/setup-complete',
    //   // };
    //   redirect: "if_required",
    // });

    if (error) {
      console.log("err", error);
      if (error.message) {
        notifyError(error.message);
      } else {
        notifyError("Paymet failed. Please try again.");
      }
      this.setState({
        showLoader: !this.state.showLoader,
      });
      return;
    }

    // if (paymentIntent) {
    //   notifySuccess("Plan purchased successfully.");
    // }

    // const paymentMethod = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements,
    // });

    // console.log("paymentMethod", paymentMethod)

    this.props.dispatch(startLoader());
    try {
      let req = {
        client_secret: this.props.selectedPurPlan.clientSecret,
        payment_details: paymentIntent,
        is_redeemed: this.props.selectedPurPlan.is_coupon_applied,
        coupon: this.props.selectedPurPlan.coupon,
        team_size: this.props.selectedPurPlan.team_size,
      };

      const { data: res } = await activateAddOns(req);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.replace("my-account");
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  showCurrencySymbol = () => {
    var currency = this.props.selectedPurPlan.currency.toUpperCase();
    if (currency === "INR") return <span className="cur_sign">₹</span>;
    else if (currency === "BWP") return <span className="cur_sign">P</span>;
    else if (currency === "GBP") return <span className="cur_sign">£</span>;
    else if (currency === "EUR") return <span className="cur_sign">€</span>;
    else return <span className="cur_sign">$</span>;
  };

  showPriceTotal = () => {
    if (this.props.selectedPurPlan.plan_name == "Quarterly") {
      return this.props.selectedPurPlan.price_usd * 3;
    } else if (this.props.selectedPurPlan.plan_name == "Yearly") {
      return this.props.selectedPurPlan.price_usd * 12;
    }
    return this.props.selectedPurPlan.price_usd;
  };

  render() {
    const { stripe } = this.props;
    const CARD_OPTIONS = {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#fff",
          color: "#fff",
          fontWeight: 500,
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": { color: "#fff" },
          "::placeholder": { color: "#fff" },
        },
        invalid: {
          iconColor: "#ffc7ee",
          color: "#ffc7ee",
        },
      },
    };
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <PaymentElement />
          <button
            type="submit"
            className="SubmitButton"
            disabled={this.state.showLoader}
          >
            {/* ${this.showPriceTotal()} - Pay Now */}
            {this.props.selectedPurPlan.is_coupon_applied ? (
              <>
                <span className="pricecrossover line-through mr-2">
                  {" "}
                  {this.showCurrencySymbol()}
                  {this.showPriceTotal()}{" "}
                </span>{" "}
                {this.showCurrencySymbol()}
                {this.props.selectedPurPlan.amount}{" "}
              </>
            ) : (
              <span>
                {this.showCurrencySymbol()}
                {/* {this.showPriceTotal()}{" "} */}
                {this.props.selectedPurPlan.amount}
              </span>
            )}{" "}
            - Pay Now
          </button>
          {this.props.selectedPurPlan.is_coupon_applied ? (
            <span className="discount-coupon-text">
              Coupon Code Applied - 20% Off
            </span>
          ) : (
            ""
          )}
          {this.state.showLoader ? (
            <div className="spinner-border mt-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}

          <div className="row" style={{ marginLeft: "28%" }}>
            <Visa style={{ margin: "10 10 10 50", width: 100, height: 40 }} />
            <Mastercard
              style={{ margin: "10 10 10 20", width: 100, height: 40 }}
            />
            <Elo style={{ margin: "10 10 10 -10", width: 100, height: 40 }} />
          </div>
        </form>
        {/* <div className="AppWrapper">
            <form onSubmit={this.handleSubmit}>
              <fieldset className="FormGroup">
                <div className="FormRow">
                  <CardElement options={CARD_OPTIONS} />
                </div>
              </fieldset>
              <button type="submit" className="SubmitButton" disabled={!stripe}>
                ${this.showPriceTotal()} - Pay Now
              </button>
            </form>
          </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    currentPlan: state.auth.currentPlan,
    selectedPurPlan: state.generalActions.plan,
  };
};

export default connect(mapStateToProps)(CheckoutForm);

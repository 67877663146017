import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import _ from "lodash";
import produce from "immer";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import { ReactSortable } from "react-sortablejs";
import moment from "moment";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent, timeEvent } from "./../utils/mixpanel";
import PhoneInput from "react-phone-input-2";
import {
  generateQuestion,
  generateQuestionFromVideo,
  wikiP1,
  wikiTopicSuggestions,
  editQuestionPaper,
  updateInstructionRead,
  fetchUser,
  getAddOnsList,
  generateQuestionFromPdfDoc,
  QuestionPaperRating,
  getQPRating,
  addPagination,
  updateUserDetails,
  btUpdateToken,
  progress,
  generateQuestionP1,
  generateQuestionsFromPdfDocP1,
  generateQuestionFromVideoP1,
  createTest,
  purchaseAddOns,
  purchaseFreePlan,
  addOnsList,
} from "./../services";
import MSG, {
  notifySuccess,
  notifyError,
  notifyInfo,
  profession,
  findUsList,
} from "./../utils/Helper";
import {
  closeReferral,
  startLoader,
  stopLoader,
  showDefaultContent,
  setInitialQues,
  setSelectedPlan,
  showReferral,
} from "./../store/reducers/generalActions";
import { authLogout } from "./../store/reducers/auth";
import EasyMCQ from "./questionTypes/easymcq";
import MediumMCQ from "./questionTypes/mediummcq";
import HardMCQ from "./questionTypes/hardmcq";
import TrueFalse from "./questionTypes/true_false";
import Descriptive from "./questionTypes/descriptive";
import FillUpsMCQ from "./questionTypes/fillups";
import Tooltip from "./common/tooltip";
import Addeasymcq from "./addquestion/addeasymcq";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Addmediummcq from "./addquestion/addmediummcq";
import Addhardmcq from "./addquestion/addhardmcq";
import Truefalse from "./addquestion/true_false";
import AddDescriptive from "./addquestion/adddescriptive";
import Fillups from "./addquestion/fillups";
import { data } from "jquery";
import { isMobile } from "react-device-detect";
import Filter from "./common/Filter";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ReferPopup from "./common/ReferPopup";
import { track } from "mixpanel-browser";
import ProgressBar from "./common/progreesBar";
import TimeInput from "./common/TimeInput";
import Toggle from "./common/Toggle";
import { ProductFruits } from "react-product-fruits";
import CustomQuestions from "./common/CustomQuestions";
import TimeField from "react-simple-timefield";
import { TextField } from "@mui/material";
import TakeAssessment from "./common/TakeAssessment";
import ToggleForGQ from "./common/ToggleForGQ";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./common/AddOnsCheckoutForm";
import QuestionPaperTopUp from "./common/QuestionPaperTopUp";
import textData from "./common/textData";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class QuestionPaperGenerate extends Component {
  state = {
    questionPaperGenerated: false,
    titleofQuestion: this.props.defaultContent?.title,
    titleofQuestion_emsg: "",
    contentForQuestions: this.props.defaultContent?.content,
    newUser: this.props.defaultContent?.flag,
    wikiSearchInput: "",
    refreshing: false,
    typing: false,
    typingTimeout: 0,
    selectedWikiTopic: null,
    tmpselectedWikiTopic: null,
    showAlert: false,
    wikiContent: "",
    searchTopic: "",
    iseditpaper: true,
    questions: [],
    currentActiveTab: 2,
    ques_count: {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    },
    addquestionmodal: false,
    questiontypetab: "easymcq",
    defaultActiveTab: "custom-tab",
    addeasymcq: false,
    addmediummcq: false,
    addhardmcq: false,
    addtruefalse: false,
    adddescriptive: false,
    addfillups: false,
    sortableQuestionOption: [
      {
        id: 1,
        name: "An object at rest stays at rest and an object in motion stays in motion.",
      },
      {
        id: 2,
        name: "Individuals define physics by what it was rather than what it is and will be.",
      },
      {
        id: 3,
        name: "What we mean by this is that things keep changing in the world of physics with every discovery.",
      },
      {
        id: 4,
        name: "As theories progress and discoveries are made, not only the answer but the whole question.",
      },
    ],
    isUploaded: false,
    videoFile: null,
    pdfDocFile: null,
    feedbackAlert: false,
    showRatingAlert: false,
    callRatingAPI: true,
    questionPaperId: null,
    questionPaperRating: null,
    highlightBadEmoji: null,
    highlightNeutralEmoji: null,
    highlightGoodEmoji: null,
    showPremiumPrompt: false,
    premiumPromptText: "",
    isShowPremiumPrompt: false,
    totalPages: 0,
    pageNo: 1,
    requiredQuestionCount: 11110,
    lessQuestionsGenerated: false,
    moreQuestionsGenerated: false,
    showPopup: false,
    firstEventScroll: false,
    activity_count: 0,
    isQuestionGenerated: false,
    source: "Direct",
    scrollAfterGenerate: false,
    leavePageEvent: false,
    showUpdateProfilePopUp: false,
    isShowProfilePopUp: false,
    source_from: "",
    source_from_other: "",
    profession: "",
    profession_other: "",
    data: {
      contact_number: "",
      country_code: "in",
      dial_code: "",
      formatted_phone_number: "",
    },
    showLoader: false,
    showBar: false,
    showProgressBar: false,
    progress_percent: 0,
    contentId: null,
    showEditContentPopUp: false,
    showWordLimit: false,
    searchTopicWordLimit: false,
    docPage: "",
    docPageStatement: "All pages are selected.",
    isCustomPages: false,
    isCustomDuration: false,
    startTime: null,
    endTime: null,
    timeInputMsg: null,
    ques_type: null,
    finalques_type: [],
    ques_count: null,
    totalQuestionsFormed: 0,
    showDurationAlert: false,
    timeSeconds: "00:00:00",
    timerSeconds: 0,
    stripePromise: null,
    stripeClientSecret: "",
    showStripeForm: false,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    showAddOn: false,
    addOnText: "",
    addOnCTAText: "",
    freeAddOnSubText: "",
    addOnPlans: [],
    activatePlan: [],
    showTopUp: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  async componentDidMount() {
    this.props.dispatch(getAddOnsList());
    this.setState({ leavePageEvent: true });
    if (this.props.isAuthenticated) {
      if (!this.props.user.name) {
        this.props.dispatch(fetchUser());
      }
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      } else {
        this.props.history.replace("/generate-questions");
      }
    }

    if (this.props.location.state != undefined) {
      const { state } = this.props.location;
      if (state.editpaper) {
        const req = {
          content_id: state.editpaperid,
        };
        this.setState({
          iseditpaper: false,
          source: "Preview Question",
        });
        this.props.dispatch(startLoader());

        try {
          const { data: res } = await editQuestionPaper(req);
          if (res.success == true) {
            notifySuccess(res.message);
            if (!_.isEmpty(res.response)) {
              let ques_count = {
                easyCount: 0,
                mediumCount: 0,
                hardCount: 0,
                descriptiveCount: 0,
                trueFalseCount: 0,
                fillUpsCount: 0,
              };

              var easy_mcq_count =
                res.response.question_table_data.total_easy_mcq;
              ques_count.easyCount = easy_mcq_count;
              ques_count.mediumCount =
                res.response.question_table_data.total_medium_mcq;
              ques_count.hardCount =
                res.response.question_table_data.total_hard_mcq;
              ques_count.trueFalseCount =
                res.response.question_table_data.total_true_false;
              ques_count.descriptiveCount =
                res.response.question_table_data.total_descriptive;
              ques_count.fillUpsCount =
                res.response.question_table_data.total_fill_ups;

              var que_per_Page = res.response.question_table_data.perPage;
              var easy_type_page_count = Math.ceil(
                easy_mcq_count / que_per_Page
              );

              const contentData = res.response.content_table_data;
              if (_.isEmpty(contentData.wiki_topic)) {
                this.setState({
                  titleofQuestion: res.response.content_table_data.topic,
                  contentForQuestions:
                    res.response.content_table_data.content_text,
                  questions: [...res.response.question_table_data.questions],
                  questionPaperGenerated: true,
                  defaultActiveTab: "custom-tab",
                  ques_count: { ...ques_count },
                  pageNo: res.response.question_table_data.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: easy_type_page_count,
                });
              } else {
                this.setState({
                  currentActiveTab: 1,
                  titleofQuestion: res.response.content_table_data.topic,
                  selectedWikiTopic: {
                    label: res.response.content_table_data.wiki_topic,
                    value: res.response.content_table_data.wiki_topic,
                  },
                  tmpselectedWikiTopic: {
                    label: res.response.content_table_data.wiki_topic,
                    value: res.response.content_table_data.wiki_topic,
                  },
                  wikiContent: res.response.content_table_data.content_text,
                  questions: [...res.response.question_table_data.questions],
                  questionPaperGenerated: true,
                  defaultActiveTab: "wiki",
                  ques_count: { ...ques_count },
                  pageNo: res.response.question_table_data.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: easy_type_page_count,
                });
              }
              if (this.props.user.update_profile_index == 0) {
                try {
                  this.scrollToQuestions();
                } catch (err) {
                  console.log(err);
                }
              }
            }
          } else {
            notifyError(res.message);
            this.resetQuestionState();
          }
        } catch (err) {
          console.log(err);
          this.resetQuestionState();
          if (err.response && err.response.status === 422) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else if (err.response && err.response.status === 401) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else {
            notifyError(MSG.commonApiError);
          }
        }
      }
      this.props.dispatch(stopLoader());
    }
    // try {
    //   const { data: res } = await addOnsList();
    //   if (res.success == true) {
    //     // notifySuccess(res.message);
    //     this.setState(
    //       {
    //         addOnsList: res.response,
    //       }
    //       // () => this.showPercentageOff()
    //     );
    //   } else {
    //     notifyError(res.message);
    //   }
    // } catch (err) {
    //   console.log(err);
    //   if (err.response && err.response.status === 422) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else if (err.response && err.response.status === 401) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else {
    //     notifyError(MSG.commonApiError);
    //   }
    // }

    // if (this.props.isAuthenticated) {
    // await this.props.dispatch(fetchUser());
    // if (this.props.user.is_free_credit == 0) {
    //   try {
    //     const req = { credits: 41 };
    //     this.props.dispatch(startLoader());
    //     const { data: res } = await updateUserDetails(req);
    //     this.props.dispatch(stopLoader());
    //     if (res.success == true) {
    //       if (res.code === 1111) {
    //         this.props.dispatch(fetchUser());
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     this.resetQuestionState();
    //     if (err.response && err.response.status === 422) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else if (err.response && err.response.status === 401) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else {
    //       notifyError(MSG.commonApiError);
    //     }
    //   }
    // }
    // }
    if (this.props.user.country_code) {
      const country_code = _.lowerCase(this.props.user.country_code);
      this.setState((prevValue) => ({
        data: { ...prevValue.data, country_code: country_code },
      }));
    }

    // if (this.props.user.is_instruction_readed == 0) {
    //   this.setState(
    //     {
    //       showAlert: true,
    //     },
    //     async () => {
    //       await updateInstructionRead();
    //       this.props.dispatch(fetchUser());
    //     }
    //   );
    // }

    trackEvent("Generate Question Page Loaded", { source: this.state.source });
    timeEvent("Leaving Generate Question Page");
  }

  componentDidUpdate() {
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count == 1) {
        trackEvent("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count == 0 &&
        this.state.source == "Direct"
      ) {
        trackEvent("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        trackEvent("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("scroll-to-div");
        if (element && this.state.source == "Direct") {
          trackEvent("Scroll Down To Bottom - Generate Question");
        }
      }
    };

    if (this.props.isAuthenticated) {
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      }
    }
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  handleCallback = (childData) => {
    let ques_count = {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    };
    ques_count.easyCount = childData.total_easy_mcq;
    ques_count.mediumCount = childData.total_medium_mcq;
    ques_count.hardCount = childData.total_hard_mcq;
    ques_count.trueFalseCount = childData.total_true_false;
    ques_count.descriptiveCount = childData.total_descriptive;
    ques_count.fillUpsCount = childData.total_fill_ups;

    var que_obj = childData.questions;
    this.setState({
      questions: [...que_obj],
      ques_count: { ...ques_count },
      pageNo: childData.page_no,
      totalPages: childData.page_no,
    });
    // console.log(this.state)

    // if (this.state.questions.length < this.state.requiredQuestionCount) {
    //   this.setState({ lessQuestionsGenerated: true });
    // }
  };

  onInputChange = (option) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      wikiSearchInput: option,
      refreshing: true,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.getOptionsAsync(option);
      }, 2000),
    });
  };

  // setQuesType = (ques_type) => {
  //   console.log(ques_type)
  //   let finalques_type = ques_type.map((el) => el.value);
  //   finalques_type = finalques_type.join(",")
  //   this.setState({ ques_type: ques_type });
  //   this.setState({ finalques_type }, () => console.log(this.state.finalques_type));
  // };

  // handleQuesCount = (e) => {
  //   const regex = /^[1-9][0-9]*$/;
  //   const value = e.target.value;
  //   if (value.length < 1) {
  //     this.setState({ ques_count: "" });
  //   } else if (value.length === 1) {
  //     const reg = /^[1-9]/;
  //     if (reg.test(value)) {
  //       this.setState({ ques_count: value });
  //     } else {
  //       this.setState({ ques_count: null})
  //     }
  //   } else if (value.length > 3) {
  //     //do nothing
  //   } else {
  //     if (regex.test(value)) {
  //       this.setState({ ques_count: value });
  //     }
  //   }
  // };

  getOptionsAsync = async (val) => {
    if (val != "") {
      this.props.dispatch(startLoader());
      try {
        var wikiFormData = new FormData();
        wikiFormData.append("wiki-topic", val);
        wikiFormData.append("generateQA", "");
        const { data: res } = await wikiTopicSuggestions(wikiFormData);
        if (res.response.topic_suggestions.length == 0) {
          notifyError(
            "We couldn't find any matching Topic. Please try with some relevant Topic Name."
          );
        }
        if (res.success == true) {
          const data = res.response.topic_suggestions;
          const ldpas = data.map((s) => ({
            label: s,
            value: s,
          }));
          this.setState({
            options: ldpas,
            wikiSearchInput: val,
          });
        }
      } catch (err) {
        console.log(err);
        // notifyError(MSG.commonApiError);
      }
      this.props.dispatch(stopLoader());
    }
  };

  checkCategory = () => {
    var category_id = 1;

    if (this.state.questiontypetab === "easymcq") category_id = 1;
    else if (this.state.questiontypetab === "mediummcq") category_id = 2;
    else if (this.state.questiontypetab === "hardmcq") category_id = 3;
    else if (this.state.questiontypetab === "truefalse") category_id = 4;
    else if (this.state.questiontypetab === "descriptive") category_id = 5;
    else if (this.state.questiontypetab === "fillups") category_id = 6;

    return category_id;
  };

  handleWikiTopicSelect = async (name, option) => {
    this.setState({
      selectedWikiTopic: option,
      tmpselectedWikiTopic: option,
      [name]: option.value,
    });
    this.props.dispatch(startLoader());
    try {
      var formData = new FormData();
      formData.append("wiki-topic", option.value);
      formData.append("generateQA", "");
      const { data: res } = await wikiTopicSuggestions(formData);
      // console.log(res);
      if (res.success == true) {
        const wikiContent = res.response.content;
        this.setState({
          wikiSearchInput: option.value,
          wikiContent: wikiContent,
        });
      }
    } catch (err) {
      console.log(err);
      // notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleWikiTopicFocus = () => {
    this.setState({
      tmpselectedWikiTopic: null,
      options: [],
      activity_count: this.state.activity_count + 1,
    });
  };

  handleWikiTopicBlur = () => {
    if (this.state.tmpselectedWikiTopic == null) {
      this.setState({
        tmpselectedWikiTopic: this.state.selectedWikiTopic,
        options: [],
      });
    }
  };

  handleGenerateQuestionForWiki = async () => {
    trackEvent("Proceeded to Generate Questions From Search Topics", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - Search Topics");
    if (this.countWords(this.state.wikiContent) < 50) {
      this.setState({ searchTopicWordLimit: true });
      return;
    }

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }

    /*if (this.props.user.remaining_runs == 0) {
      notifyError(
        "You don't have runs to generate question. Please purchase plan"
      );
      this.props.history.push("/plans");
      return;
    } */

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    // this.props.dispatch(startLoader());
    //if (this.state.callRatingAPI == true) {
    try {
      const { data: res } = await getQPRating();
      if (res.success == true && res.rating == null) {
        this.setState({
          feedbackAlert: true,
          questionPaperId: res.response.content_id,
          questionPaperRating: res.response.rating,
          callRatingAPI: false,
        });
        if (this.props.user.update_profile_index == 0) {
          if (
            this.state.feedbackAlert == true &&
            this.state.questionPaperRating == null
          ) {
            this.setState({
              showRatingAlert: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    //console.log("wiki-content",this.state.wikiContent)
    // console.log("wiki-topic", this.state.selectedWikiTopic.value);
    //console.log("this.state.generateQA",this.state.generateQA)

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    const { quesCount, quesTypes } = this.props.customQuesType;

    var myformData = new FormData();
    myformData.append("topic", this.state.titleofQuestion);
    myformData.append("wiki-topic", this.state.selectedWikiTopic.value);
    myformData.append("wiki-content", this.state.wikiContent);
    myformData.append("device", device);
    myformData.append("quesType", quesTypes);
    if (quesCount) myformData.append("quesCount", quesCount);
    myformData.append("generateQA", "GenerateQuestions");

    try {
      const { data: res } = await wikiP1(myformData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (this.props.user.update_profile_index) {
          if (this.state.showRatingAlert) {
            this.setState({
              isShowProfilePopUp: true,
            });
          } else {
            this.setState(
              {
                showUpdateProfilePopUp: true,
              } //,
              // () => {
              //   notifyError("Update Your Profile to See Questions");
              // }
            );
          }
        }

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          var formData = new FormData();
          formData.append("wiki-content", this.state.wikiContent);
          formData.append("content_id", contentId);
          formData.append("category_id", category_id);
          formData.append("generateQA", "GenerateQuestions");

          try {
            const { data: res } = await wikiTopicSuggestions(formData);
            if (res.success == true) {
              notifySuccess(res.message);
              this.props.dispatch(fetchUser());

              // if (res.code == 1111) {
              //   /* Check if new notification addded to db and update it */
              //   this.props.dispatch(fetchUser());
              // }
              var que_obj = res.response.questions;
              if (!_.isEmpty(res.response)) {
                trackEvent("Questions Generated Successfully - Search Topics", {
                  PlanName: this.props.user.plan_details.plan_name,
                });
                timeEvent("Trying Next Content");

                const total_ques_formed =
                  res.response.total_generated_questions;
                const total_ques_expected = res.response.expected_records;

                if (total_ques_expected) {
                  if (total_ques_expected !== "N/A") {
                    if (total_ques_formed < total_ques_expected) {
                      this.setState({ lessQuestionsGenerated: true });
                    } else if (total_ques_formed > total_ques_expected) {
                      this.setState({ moreQuestionsGenerated: true });
                    }
                  }
                }

                let ques_count = {
                  easyCount: 0,
                  mediumCount: 0,
                  hardCount: 0,
                  descriptiveCount: 0,
                  trueFalseCount: 0,
                  fillUpsCount: 0,
                };

                var total_records = res.response.total_easy_mcq;
                if (category_id == 2)
                  var total_records = res.response.total_medium_mcq;
                if (category_id == 3)
                  var total_records = res.response.total_hard_mcq;
                if (category_id == 4)
                  var total_records = res.response.total_true_false;
                if (category_id == 5)
                  var total_records = res.response.total_descriptive;
                if (category_id == 6)
                  var total_records = res.response.total_fill_ups;

                ques_count.easyCount = res.response.total_easy_mcq;
                ques_count.mediumCount = res.response.total_medium_mcq;
                ques_count.hardCount = res.response.total_hard_mcq;
                ques_count.trueFalseCount = res.response.total_true_false;
                ques_count.descriptiveCount = res.response.total_descriptive;
                ques_count.fillUpsCount = res.response.total_fill_ups;

                var que_per_Page = res.response.perPage;
                var totalPages = Math.ceil(total_records / que_per_Page);

                this.setState({
                  questions: [...que_obj],
                  questionPaperGenerated: true,
                  ques_count: { ...ques_count },
                  pageNo: res.response.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: totalPages,
                  isQuestionGenerated: true,
                  totalQuestionsFormed: total_ques_formed,
                });
                if (this.props.user.update_profile_index == 0) {
                  try {
                    this.scrollToQuestions();
                  } catch (err) {
                    console.log(err);
                  }
                }
                await this.sleep(1000);
                this.setState({
                  scrollAfterGenerate: true,
                });
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - Search Topics");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Search Topics");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        notifyError(res.message);

        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            // Code 1199 Signals to Purchase Free Plan.
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
            });
          }

          if (res.code == 1031) {
            // Code 1031 means Question Paper Limits Exhausted and Show Top UP Question Papers Prompt.
            this.setState({
              showRatingAlert: false,
              showTopUp: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  resetQuestionState = () => {
    this.setState({
      questions: [],
      questionPaperGenerated: false,
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
    });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("scroll-to-div").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleAddQuestionOnClick = () => {
    this.addquestion();
    this.scrollToBottom();
  };

  getProgressPercentage = async (contentId) => {
    this.intervalId = setInterval(async () => {
      if (this.state.progress_percent < 100) {
        try {
          const { data: res } = await progress({ content_id: contentId });
          if (res.success == true) {
            this.setState({
              progress_percent: res.response.progress,
            });
          }
        } catch (err) {
          console.log("err", err);
          // notifyError(MSG.commonApiError);
        }
      }
    }, 1000);
  };

  countWords = (str) => {
    const arr = str.split(" ");
    return arr.filter((word) => word !== "").length;
  };

  handleGenerateQuestion = async () => {
    // trackEvent("Proceeded to Generate Questions From Type/Paste Text", {
    //   clicks: this.state.activity_count,
    // });
    // timeEvent("Questions Generated Successfully - Type/Paste Text");

    // if (this.props.user.limits.free_trial_ends) {
    //   this.setState({
    //     showAddOn: true,
    //     activatePlan: this.props.addOnsList.Free,
    //   });
    //   return;
    // }

    if (this.countWords(this.state.contentForQuestions) < 50) {
      this.setState({ showWordLimit: true });
      return;
    }

    if (
      this.state.newUser &&
      this.props.defaultContent.content === this.state.contentForQuestions
    ) {
      this.setState({ showEditContentPopUp: true });
      return;
    }

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }
    /*if (this.props.user.remaining_runs == 0) {
      notifyError(
        "You don't have runs to generate question. Please purchase plan"
      );
      this.props.history.push("/plans");
      return;
    } */

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    // this.props.dispatch(startLoader());
    //if (this.state.callRatingAPI == true) {
    try {
      const { data: res } = await getQPRating();
      if (res.success == true && res.rating == null) {
        this.setState({
          feedbackAlert: true,
          questionPaperId: res.response.content_id,
          questionPaperRating: res.response.rating,
          callRatingAPI: false,
        });
        if (this.props.user.update_profile_index == 0) {
          if (
            this.state.feedbackAlert == true &&
            this.state.questionPaperRating == null
          ) {
            this.setState({
              showRatingAlert: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    //this.props.dispatch(startLoader());
    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var myformData = new FormData();
    const { quesCount, quesTypes } = this.props.customQuesType;

    myformData.append("topic", this.state.titleofQuestion);
    myformData.append("content", this.state.contentForQuestions);
    myformData.append("device", device);
    myformData.append("quesType", quesTypes);
    if (quesCount) myformData.append("quesCount", quesCount);
    // console.log(myformData);
    /* const req = {
      topic: this.state.titleofQuestion,
      content: this.state.contentForQuestions,
    }; */

    try {
      // const { data: res } = await generateQuestion(req);
      const { data: res } = await generateQuestionP1(myformData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (this.props.user.update_profile_index) {
          if (this.state.showRatingAlert) {
            this.setState({
              isShowProfilePopUp: true,
            });
          } else {
            this.setState(
              {
                showUpdateProfilePopUp: true,
              } //,
              // () => {
              //   notifyError("Update Your Profile to See Questions");
              // }
            );
          }
        }

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            // const { data: res } = await generateQuestion(req);
            const { data: res } = await generateQuestion({
              content_id: contentId,
              category_id: category_id,
            });

            if (res.success == true) {
              this.props.dispatch(fetchUser());

              notifySuccess(res.message);
              // if (res.code == 1111) {
              //   /* Check if new notification addded to db and update it */
              //   this.props.dispatch(fetchUser());
              // }
              var que_obj = res.response.questions;

              if (!_.isEmpty(res.response)) {
                const total_ques_formed =
                  res.response.total_generated_questions;
                const total_ques_expected = res.response.expected_records;

                if (total_ques_expected) {
                  if (total_ques_expected !== "N/A") {
                    if (total_ques_formed < total_ques_expected) {
                      this.setState({ lessQuestionsGenerated: true });
                    } else if (total_ques_formed > total_ques_expected) {
                      this.setState({ moreQuestionsGenerated: true });
                    }
                  }
                }

                let ques_count = {
                  easyCount: 0,
                  mediumCount: 0,
                  hardCount: 0,
                  descriptiveCount: 0,
                  trueFalseCount: 0,
                  fillUpsCount: 0,
                };

                var total_records = res.response.total_easy_mcq;
                if (category_id == 2)
                  var total_records = res.response.total_medium_mcq;
                if (category_id == 3)
                  var total_records = res.response.total_hard_mcq;
                if (category_id == 4)
                  var total_records = res.response.total_true_false;
                if (category_id == 5)
                  var total_records = res.response.total_descriptive;
                if (category_id == 6)
                  var total_records = res.response.total_fill_ups;

                ques_count.easyCount = res.response.total_easy_mcq;
                ques_count.mediumCount = res.response.total_medium_mcq;
                ques_count.hardCount = res.response.total_hard_mcq;
                ques_count.trueFalseCount = res.response.total_true_false;
                ques_count.descriptiveCount = res.response.total_descriptive;
                ques_count.fillUpsCount = res.response.total_fill_ups;

                var que_per_Page = res.response.perPage;
                var totalPages = Math.ceil(total_records / que_per_Page);

                this.setState({
                  questions: [...que_obj],
                  questionPaperGenerated: true,
                  ques_count: { ...ques_count },
                  pageNo: res.response.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: totalPages,
                  isQuestionGenerated: true,
                  totalQuestionsFormed: total_ques_formed,
                  //callRatingAPI: true,
                });
                // document.getElementById("v-pills-tabContent").style.display =
                //   "block";
                // document.getElementById("v-pills-easy").style.display = "block";

                // if (
                //   this.state.questions.length < this.state.requiredQuestionCount
                // ) {
                //   this.setState({ lessQuestionsGenerated: true });
                // } else {
                //   this.setState({ moreQuestionsGenerated: true });
                // }

                if (this.props.user.update_profile_index == 0) {
                  try {
                    this.scrollToQuestions();
                  } catch (err) {
                    console.log(err);
                  }
                }

                await this.sleep(1000);
                this.setState({
                  scrollAfterGenerate: true,
                });
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - Type/Paste Text");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Type/Paste Text");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        notifyError(res.message);

        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
            });
          }

          if (res.code == 1031) {
            this.setState({
              showRatingAlert: false,
              showTopUp: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  //GET Timestamp for the video files
  startTimeStampHandler = (secs) => {
    this.setState({ startTime: secs });
  };

  endTimeStampHandler = (secs) => {
    this.setState({ endTime: secs });
  };

  isCustomDurationHandler = () => {
    this.setState({ isCustomDuration: true });
  };

  startTypingTime = () => {
    if (this.state.timeInputMsg) {
      this.setState({ timeInputMsg: null });
    }
  };

  handleClearCustomDuration = () => {
    this.setState({
      isCustomDuration: false,
      timeInputMsg: null,
      startTime: null,
      endTime: null,
    });
  };

  validateTimeInput = () => {
    const { startTime, endTime } = this.state;
    if (!startTime || !endTime) {
      return true;
    } else if (startTime === endTime) {
      this.setState({ timeInputMsg: "Start & End time can't be same" });
      return false;
    } else if (startTime > endTime) {
      this.setState({ timeInputMsg: "End time can't be less than Start time" });
      return false;
    }
    return true;
  };

  getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => {
          return f.fileObject;
        });
        this.setState({ videoFile: fileObj[0], isUploaded: true });
      });
    });
  };

  handleFileStatusChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ lengthOfVideo: meta.duration });
    }
    if (status == "removed") {
      this.setState({ videoFile: null, isUploaded: false });
    }
  };

  getDocPdfFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => f.fileObject);
        this.setState({ pdfDocFile: fileObj[0] });
      });
    });
  };

  handlePdfDocFileStatusChange = ({ meta, file }, status) => {
    if (status == "removed") {
      this.setState({ pdfDocFile: null });
    }
  };

  validateTopicInput = () => {
    if (_.trim(this.state.titleofQuestion) == "") {
      this.setState(
        {
          titleofQuestion_emsg: "Please enter Chapter name, Any topic, etc.",
        },
        () => {
          const position = document.getElementById("titleofQuestion").offsetTop;
          window.scrollTo(0, position);
          document.getElementById("titleofQuestion").focus();
        }
      );
      return false;
    }
    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  videoExtractButtonContent = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          <React.Fragment>
            {!this.props.user.plan_details.video_is_allowed ? (
              <Link type="button" className="btn--submit" to="/plans">
                Upgrade Plan
              </Link>
            ) : (
              <button
                type="button"
                className="btn--submit"
                onClick={this.handleGenerateQuestionFromVideo}
                disabled={
                  this.state.videourl || this.state.isUploaded ? false : true
                }
              >
                Extract Content
              </button>
            )}
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  handleGenerateQuestionFromVideo = async () => {
    trackEvent("Proceeded to Generate Questions From Video/URL", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - Video/URL");

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }
    if (!this.validateTimeInput()) {
      return false;
    }

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    // this.props.dispatch(startLoader());
    try {
      const { data: res } = await getQPRating();
      if (res.success == true && res.rating == null) {
        this.setState({
          feedbackAlert: true,
          questionPaperId: res.response.content_id,
          questionPaperRating: res.response.rating,
          callRatingAPI: false,
        });
        if (this.props.user.update_profile_index == 0) {
          if (
            this.state.feedbackAlert == true &&
            this.state.questionPaperRating == null
          ) {
            this.setState({
              showRatingAlert: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var formData = new FormData();
    const { quesCount, quesTypes } = this.props.customQuesType;

    formData.append("topic", this.state.titleofQuestion);
    formData.append("device", device);
    formData.append("start_point", this.state.startTime);
    formData.append("end_point", this.state.endTime);
    formData.append("quesType", quesTypes);
    if (quesCount) formData.append("quesCount", quesCount);

    let filetype = "";
    if (this.state.isUploaded == true) {
      formData.append("file", this.state.videoFile);
      formData.append("type", "video");
      filetype = "video";
    } else {
      formData.append("url", this.state.videourl);
      formData.append("type", "url");
      filetype = "url";
    }
    try {
      // const { data: res } = await generateQuestion(req);
      const { data: res } = await generateQuestionFromVideoP1(formData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (this.props.user.update_profile_index) {
          if (this.state.showRatingAlert) {
            this.setState({
              isShowProfilePopUp: true,
            });
          } else {
            this.setState(
              {
                showUpdateProfilePopUp: true,
              } //,
              // () => {
              //   notifyError("Update Your Profile to See Questions");
              // }
            );
          }
        }

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            const { data: res } = await generateQuestionFromVideo({
              type: filetype,
              content_id: contentId,
              category_id: category_id,
            });
            //console.log("res",res)
            if (res.success == true) {
              notifySuccess(res.message);
              this.props.dispatch(fetchUser());

              // if (res.code == 1111) {
              //   /* Check if new notification addded to db and update it */
              //   this.props.dispatch(fetchUser());
              // }
              var que_obj = res.response.questions;
              if (!_.isEmpty(res.response)) {
                const total_ques_formed =
                  res.response.total_generated_questions;
                const total_ques_expected = res.response.expected_records;

                if (total_ques_expected) {
                  if (total_ques_expected !== "N/A") {
                    // console.log(total_ques_expected , total_ques_formed)
                    if (total_ques_formed < total_ques_expected) {
                      this.setState({ lessQuestionsGenerated: true });
                    }
                  }
                }

                let ques_count = {
                  easyCount: 0,
                  mediumCount: 0,
                  hardCount: 0,
                  descriptiveCount: 0,
                  trueFalseCount: 0,
                  fillUpsCount: 0,
                };

                var total_records = res.response.total_easy_mcq;
                if (category_id == 2)
                  var total_records = res.response.total_medium_mcq;
                if (category_id == 3)
                  var total_records = res.response.total_hard_mcq;
                if (category_id == 4)
                  var total_records = res.response.total_true_false;
                if (category_id == 5)
                  var total_records = res.response.total_descriptive;
                if (category_id == 6)
                  var total_records = res.response.total_fill_ups;

                ques_count.easyCount = res.response.total_easy_mcq;
                ques_count.mediumCount = res.response.total_medium_mcq;
                ques_count.hardCount = res.response.total_hard_mcq;
                ques_count.trueFalseCount = res.response.total_true_false;
                ques_count.descriptiveCount = res.response.total_descriptive;
                ques_count.fillUpsCount = res.response.total_fill_ups;

                var que_per_Page = res.response.perPage;
                var totalPages = Math.ceil(total_records / que_per_Page);

                this.setState({
                  questions: [...que_obj],
                  questionPaperGenerated: true,
                  ques_count: { ...ques_count },
                  pageNo: res.response.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: totalPages,
                  isQuestionGenerated: true,
                  totalQuestionsFormed: total_ques_formed,
                });
                if (this.props.user.update_profile_index == 0) {
                  try {
                    this.scrollToQuestions();
                  } catch (err) {
                    console.log(err);
                  }
                }
                await this.sleep(1000);
                this.setState({
                  scrollAfterGenerate: true,
                });
              }
            } else {
              this.resetQuestionState();
              notifyError(res.message);
              if (!this.props.user.plan_details.is_new_pricing) {
                if (
                  res.code === 111 &&
                  this.props.user.plan_details.plan_name === "Free"
                ) {
                  this.setState({
                    premiumPromptText: res.message,
                    isShowPremiumPrompt: true,
                  });
                  if (this.state.showRatingAlert == false) {
                    this.setState({
                      showPremiumPrompt: true,
                    });
                  }
                }
              } else {
                if (res.code == 1199) {
                  this.setState({
                    showRatingAlert: false,
                    showAddOn: true,
                    activatePlan: this.props.addOnsList.Free,
                    addOnText: textData.FreeAddOnText,
                    addOnCTAText: textData.AddOnCTAText4,
                    freeAddOnSubText: textData.FreeAddOnSubText.replace(
                      "{price}",
                      this.props.addOnsList.Free.currency_symbol +
                        this.props.addOnsList.Free.price
                    ),
                    showProgressBar: false,
                    contentId: null,
                    progress_percent: 0,
                  });
                }

                if (res.code == 1031) {
                  this.setState({
                    showRatingAlert: false,
                    showTopUp: true,
                  });
                }

                if (this.props.user.plan_details.active_subscription) {
                  let active_plan = this.props.user.plan_details.plan_name;
                  if (res.code == 1099) {
                    this.setState({
                      showRatingAlert: false,
                      showAddOn: true,
                      activatePlan: this.props.addOnsList.Video[active_plan],
                      addOnText: textData.VideoAddOnText,
                      addOnCTAText: textData.AddOnCTAText2.replace(
                        "{price}",
                        this.props.addOnsList.Video[active_plan]
                          .currency_symbol +
                          this.props.addOnsList.Video[active_plan].price
                      ),
                    });
                  }
                }
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Video/URL");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        notifyError(res.message);
        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
              showProgressBar: false,
              contentId: null,
              progress_percent: 0,
            });
          }

          if (res.code == 1031) {
            this.setState({
              showRatingAlert: false,
              showTopUp: true,
            });
          }

          if (this.props.user.plan_details.active_subscription) {
            let active_plan = this.props.user.plan_details.plan_name;
            if (res.code == 1099) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Video[active_plan],
                addOnText: textData.VideoAddOnText,
                addOnCTAText: textData.AddOnCTAText2.replace(
                  "{price}",
                  this.props.addOnsList.Video[active_plan].currency_symbol +
                    this.props.addOnsList.Video[active_plan].price
                ),
              });
            }
          }
        }
      }
    } catch (err) {
      trackEvent("Questions Generation Failed - Video/URL");
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  // To show custom page input field
  customPageHandler = () => {
    this.setState({ isCustomPages: true });
  };

  // handle
  handleClearCustomPages = () => {
    this.setState({ docPage: "", isCustomPages: false });
  };

  //  Set value for page input and validation only accepts (1,2,6-7,8 kind of values)
  handleDocPage = (event) => {
    // To Check if user have pressed backspace and delete the value
    const pressedBackSpace =
      event.nativeEvent.inputType === "deleteContentBackward";
    if (pressedBackSpace) {
      this.setState((prevValue) => {
        let previousPages = prevValue.docPage;
        if (previousPages) {
          previousPages = previousPages.substr(0, previousPages.length - 1);
        }
        return { docPage: previousPages };
      });
      return;
    }

    // regex to check if entered values are only numbers commas and hyphens
    const regex = /^[\d,-]+$/;
    let value = event.target.value;

    if (value.startsWith("-") || value.startsWith(",")) {
      return;
    }
    if (value.trim() !== "") {
      if (!pressedBackSpace && value.length > 200) {
        return;
      }
      if (value.endsWith("--")) {
        const lastIndex = value.lastIndexOf("-");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(",,")) {
        const lastIndex = value.lastIndexOf(",");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(" ")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          }
          const replaced = lastvalue + ",";
          return { docPage: replaced };
        });
      } else if (value.endsWith("-") || value.endsWith(",")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          } else if (lastvalue.length > 1 && value.endsWith("-")) {
            const pattern = /\d+$/;
            let matches = lastvalue.match(pattern);
            if (
              lastvalue.charAt(lastvalue.length - (matches[0].length + 1)) ===
              "-"
            ) {
              return;
            }
          }
          return { docPage: value };
        });
      } else {
        if (regex.test(value)) {
          this.setState({ docPage: value });
        }
      }
    } else {
      this.setState({
        docPage: "",
        docPageStatement: "All pages are selected.",
      });
    }
  };
  // To remove - or , if any in the end
  getFinalPageList = () => {
    const docPage = this.state.docPage;
    if (!_.isEmpty(docPage)) {
      if (docPage.endsWith("-") || docPage.endsWith(",")) {
        let newDocPage = docPage.substr(0, docPage.length - 1);
        this.setState({ docPage: newDocPage });
      }
    }
  };

  handleGenerateQuestionFromPdfDoc = async () => {
    trackEvent("Proceeded to Generate Questions From PDF/Doc", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - PDF/Doc");

    this.getFinalPageList();

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    // this.props.dispatch(startLoader());

    try {
      const { data: res } = await getQPRating();
      if (res.success == true && res.rating == null) {
        this.setState({
          feedbackAlert: true,
          questionPaperId: res.response.content_id,
          questionPaperRating: res.response.rating,
          callRatingAPI: false,
        });
        if (this.props.user.update_profile_index == 0) {
          if (
            this.state.feedbackAlert == true &&
            this.state.questionPaperRating == null
          ) {
            this.setState({
              showRatingAlert: true,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var formData = new FormData();

    const { quesCount, quesTypes } = this.props.customQuesType;

    formData.append("topic", this.state.titleofQuestion);
    formData.append("file", this.state.pdfDocFile);
    formData.append("device", device);
    formData.append("pageCounts", this.state.docPage);
    formData.append("quesType", quesTypes);
    if (quesCount) formData.append("quesCount", quesCount);

    try {
      // const { data: res } = await generateQuestion(req);
      const { data: res } = await generateQuestionsFromPdfDocP1(formData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (this.props.user.update_profile_index) {
          if (this.state.showRatingAlert) {
            this.setState({
              isShowProfilePopUp: true,
            });
          } else {
            this.setState(
              {
                showUpdateProfilePopUp: true,
              } //,
              // () => {
              //   notifyError("Update Your Profile to See Questions");
              // }
            );
          }
        }

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            const { data: res } = await generateQuestionFromPdfDoc({
              content_id: contentId,
              category_id: category_id,
            });
            //console.log("res",res)
            if (res.success == true) {
              notifySuccess(res.message);
              this.props.dispatch(fetchUser());

              // if (res.code == 1111) {
              //   /* Check if new notification addded to db and update it */
              //   this.props.dispatch(fetchUser());
              // }
              var que_obj = res.response.questions;
              if (!_.isEmpty(res.response)) {
                const total_ques_formed =
                  res.response.total_generated_questions;
                const total_ques_expected = res.response.expected_records;

                if (total_ques_expected) {
                  if (total_ques_expected !== "N/A") {
                    if (total_ques_formed < total_ques_expected) {
                      this.setState({ lessQuestionsGenerated: true });
                    } else if (total_ques_formed > total_ques_expected) {
                      this.setState({ moreQuestionsGenerated: true });
                    }
                  }
                }

                let ques_count = {
                  easyCount: 0,
                  mediumCount: 0,
                  hardCount: 0,
                  descriptiveCount: 0,
                  trueFalseCount: 0,
                  fillUpsCount: 0,
                };

                var total_records = res.response.total_easy_mcq;
                if (category_id == 2)
                  var total_records = res.response.total_medium_mcq;
                if (category_id == 3)
                  var total_records = res.response.total_hard_mcq;
                if (category_id == 4)
                  var total_records = res.response.total_true_false;
                if (category_id == 5)
                  var total_records = res.response.total_descriptive;
                if (category_id == 6)
                  var total_records = res.response.total_fill_ups;

                ques_count.easyCount = res.response.total_easy_mcq;
                ques_count.mediumCount = res.response.total_medium_mcq;
                ques_count.hardCount = res.response.total_hard_mcq;
                ques_count.trueFalseCount = res.response.total_true_false;
                ques_count.descriptiveCount = res.response.total_descriptive;
                ques_count.fillUpsCount = res.response.total_fill_ups;

                var que_per_Page = res.response.perPage;
                var totalPages = Math.ceil(total_records / que_per_Page);

                this.setState({
                  questions: [...que_obj],
                  questionPaperGenerated: true,
                  ques_count: { ...ques_count },
                  pageNo: res.response.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: totalPages,
                  isQuestionGenerated: true,
                  totalQuestionsFormed: total_ques_formed,
                });
                if (this.props.user.update_profile_index == 0) {
                  try {
                    this.scrollToQuestions();
                  } catch (err) {
                    console.log(err);
                  }
                }
                await this.sleep(1000);
                this.setState({
                  scrollAfterGenerate: true,
                });
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - PDF/Doc");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - PDF/Doc");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        notifyError(res.message);

        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
              showProgressBar: false,
              contentId: null,
              progress_percent: 0,
            });
            return;
          }

          if (res.code == 1031) {
            this.setState({
              showRatingAlert: false,
              showTopUp: true,
            });
          }

          if (this.props.user.plan_details.active_subscription) {
            let active_plan = this.props.user.plan_details.plan_name;
            if (res.code == 1099) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Document[active_plan],
                addOnText: textData.UploadDocumentAddOnText,
                addOnCTAText: textData.AddOnCTAText2.replace(
                  "{price}",
                  this.props.addOnsList.Document[active_plan].currency_symbol +
                    this.props.addOnsList.Document[active_plan].price
                ),
              });
            }
          }
        }
      }
    } catch (err) {
      trackEvent("Questions Generation Failed - PDF/Doc");
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });

    if (this.state.searchTopicWordLimit)
      this.setState({ searchTopicWordLimit: false });

    this.setState({
      [input.name]: input.value,
    });
  };

  handleTitleChange = ({ currentTarget: input }) => {
    if (_.trim(input.value) != "") {
      this.setState({
        titleofQuestion_emsg: "",
      });
    }
    this.setState({
      [input.name]: input.value,
    });
  };

  handleQuestionUpdate = (question) => {
    // console.log(question);
    this.setState(
      produce(this.state, (draftState) => {
        const index = draftState.questions.findIndex(
          (m) => m.question_id === question.question_id
        );
        draftState.questions[index] = question;
      })
    );
  };

  handlestateforaddquestion = (newquestion) => {
    switch (newquestion.category_type) {
      case 1:
        this.state.ques_count.easyCount += 1;
        break;
      case 2:
        this.state.ques_count.mediumCount += 1;
        break;
      case 3:
        this.state.ques_count.hardCount += 1;
        break;
      case 4:
        this.state.ques_count.trueFalseCount += 1;
        break;
      case 5:
        this.state.ques_count.descriptiveCount += 1;
        break;
      case 6:
        this.state.ques_count.fillUpsCount += 1;
        break;
      default:
        break;
    }
    this.setState({
      questions: [...this.state.questions, newquestion],
    });
  };

  addquestion = () => {
    // console.log("op", this.state.questiontypetab);
    trackEvent("Add Question Button Clicked", {
      category: this.state.questiontypetab,
      source: this.state.source,
    });

    switch (this.state.questiontypetab) {
      case "easymcq":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "mediummcq":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "hardmcq":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "truefalse":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "descriptive":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "fillups":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handlediscard = (questiontype) => {
    switch (questiontype) {
      case "easymcq":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "mediummcq":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "hardmcq":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "truefalse":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "descriptive":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "fillups":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handleQPRating = async () => {
    if (this.state.questionPaperRating == null) {
      notifyError("Please Rate Question Paper First!");
      return false;
    }
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    const QP_Rating_Parm = {
      content_id: this.state.questionPaperId,
      rating: this.state.questionPaperRating,
    };
    //this.props.dispatch(startLoader());
    try {
      const { data: res } = await QuestionPaperRating(QP_Rating_Parm);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          questionPaperRating: null,
          highlightBadEmoji: null,
          highlightNeutralEmoji: null,
          highlightGoodEmoji: null,
        });
        this.handleRatingPromptCloseEvent();
        //this.setState({ showRatingAlert: false, feedbackAlert: false });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    //this.props.dispatch(stopLoader());
  };

  handleQPRatingChange = (value) => {
    this.setState({
      questionPaperRating: value,
    });
  };

  getRateText = (rate) => {
    if (rate == 1) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Bad";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 2) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Neutral";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 3) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Good";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else {
      document.getElementById("labelFeedbackLabel").innerHTML = "";
      document.getElementById("labelFeedbackLabel").style.width = "0";
    }
  };

  handleRatingPromptCloseEvent = () => {
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    if (this.state.isShowPremiumPrompt) {
      this.setState({
        showPremiumPrompt: true,
      });
    } else if (this.state.isShowProfilePopUp) {
      this.setState({
        showUpdateProfilePopUp: true,
        isShowProfilePopUp: false,
      });
    } else return;
  };

  handlePagination = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addPagination(req);
      if (res.success == true) {
        notifySuccess(res.message);
        var que_obj = res.response.questions;

        if (!_.isEmpty(que_obj)) {
          let ques_count = {
            easyCount: 0,
            mediumCount: 0,
            hardCount: 0,
            descriptiveCount: 0,
            trueFalseCount: 0,
            fillUpsCount: 0,
          };

          ques_count.easyCount = res.response.total_easy_mcq;
          ques_count.mediumCount = res.response.total_medium_mcq;
          ques_count.hardCount = res.response.total_hard_mcq;
          ques_count.trueFalseCount = res.response.total_true_false;
          ques_count.descriptiveCount = res.response.total_descriptive;
          ques_count.fillUpsCount = res.response.total_fill_ups;

          const que_type = que_obj[0].category_type;
          var que_per_Page = res.response.perPage;

          if (que_type == 1) {
            var que_count = res.response.total_easy_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 2) {
            var que_count = res.response.total_medium_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 3) {
            var que_count = res.response.total_hard_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 4) {
            var que_count = res.response.total_true_false;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 5) {
            var que_count = res.response.total_descriptive;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 6) {
            var que_count = res.response.total_fill_ups;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else {
            var que_count = 0;
            var page_count = 0;
          }

          this.setState({
            questions: [...que_obj],
            questionPaperGenerated: true,
            ques_count: { ...ques_count },
            pageNo: res.response.page_no,
            totalPages: page_count,
          });
          try {
            this.scrollToQuestions();
          } catch (err) {
            console.log(err);
          }
          await this.sleep(1000);
          this.setState({
            scrollAfterGenerate: true,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  nextPage = () => {
    if (this.state.pageNo == this.state.totalPages) {
      return;
    }
    var pageNo = this.state.pageNo + 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
    };

    this.handlePagination(req);
  };

  previousPage = () => {
    if (this.state.pageNo == 1) {
      return;
    }
    var pageNo = this.state.pageNo - 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
    };
    this.handlePagination(req);
  };

  handleQueTypeClick = (e, quetype, category) => {
    e.preventDefault();
    this.setState({ questiontypetab: quetype });
    const ques_count = this.state.ques_count;
    if (category == 1) {
      trackEvent("Easy MCQ Category Clicked");
      if (ques_count.easyCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 2) {
      trackEvent("Medium MCQ Category Clicked");
      if (ques_count.mediumCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 3) {
      trackEvent("Hard MCQ Category Clicked");
      if (ques_count.hardCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 4) {
      trackEvent("True/False Category Clicked");
      if (ques_count.trueFalseCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 5) {
      trackEvent("Descriptive Category Clicked");
      if (ques_count.descriptiveCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 6) {
      trackEvent("Fillups Category Clicked");
      if (ques_count.fillUpsCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    }
    var que_obj = this.state.questions[0];
    var req = {
      content_id: que_obj.content_id,
      category_id: category,
      page_no: 1,
    };

    this.handlePagination(req);
  };

  // Showing the filters selected

  showSelectedFilter = () => {
    return (
      <div className="selected_filters">
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
      </div>
    );
  };

  handleClose = () => {
    this.props.dispatch(closeReferral());
  };

  updateActivityCount = (e) => {
    if (e.target.className == "dzu-input") {
      var increment = this.state.activity_count + 1;
      this.setState({
        activity_count: increment,
      });
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };

  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  UpdateProfileSubmit = async () => {
    let req = {};
    if (this.props.user.update_profile_index == 1) {
      if (this.state.profession == "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.profession == "Others" &&
        this.state.profession_other == ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        profession: this.state.profession,
        profession_other: this.state.profession_other,
      };
    } else if (this.props.user.update_profile_index == 2) {
      if (this.state.source_from == "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.source_from == "Others" &&
        this.state.source_from_other == ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        source_from: this.state.source_from,
        source_from_other: this.state.source_from_other,
      };
    } else if (this.props.user.update_profile_index == 3) {
      if (this.state.data.contact_number == "") {
        notifyError("Please enter your phone details");
        return;
      }

      var reqObj = this.state.data;
      req = { ...reqObj };
      req.contact_number = req.contact_number.substring(
        req.dial_code.length - 1
      );
    }

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails(req);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  redirectToBloom = async () => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await btUpdateToken();
      if (res.success == true) {
        window.open(
          `${process.env.REACT_APP_HOTSPREPAI_APP_URL}/sso?token=${res.response.token}`
        );
      } else {
        notifyError("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleGenerateAnyway = () => {
    this.setState({ newUser: false, showEditContentPopUp: false }, () =>
      this.handleGenerateQuestion()
    );
    this.props.dispatch(
      showDefaultContent({
        title: "",
        content: ``,
        flag: true,
      })
    );
  };

  handleUpdateContent = () => {
    this.setState({
      newUser: false,
      showEditContentPopUp: false,
      titleofQuestion: "",
      contentForQuestions: "",
    });

    const position = document.getElementById("titleofQuestion").offsetTop;
    window.scrollTo(0, position);
    document.getElementById("titleofQuestion").focus();
    this.props.dispatch(
      showDefaultContent({
        title: "",
        content: ``,
        flag: true,
      })
    );
  };

  handleCreateTest = async () => {
    if (!this.props.user.plan_details.is_group_assessment) {
      return;
    }
    this.props.dispatch(startLoader());
    var content_id = this.state.questions[0].content_id;
    try {
      const { data: res } = await createTest({
        content_id: content_id,
      });
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.push({
          pathname: "/test-preview",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  beforeAssessment = () => {
    const { content_id, topic } = this.state.questions[0];
    this.setState({
      showDurationAlert: true,
      currentContentId: content_id,
      currentTopicName: topic,
    });
  };

  callbackFunc = (topic, show) => {
    this.setState({ showDurationAlert: false, currentTopicName: topic });
  };

  displayStripe = async (plan) => {
    if (plan.plan_name === "Free") {
      this.props.history.replace("/plans");
      return;
    }

    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    try {
      if (plan.plan_name === "Free") {
        var api_name = purchaseFreePlan;
        var req = {
          plan_id: plan.plan_id,
        };
      } else {
        var api_name = purchaseAddOns;
        var req = {
          add_on_id: plan.plan_id,
        };
      }

      const { data: res } = await api_name(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
          // price_usd: plan.price,
          // plan_name: plan.plan_name,
          // currency:
          //   this.state.plans["Monthly"][this.state.questionPaperCountSolo][
          //     "currency"
          //   ],
          // team_size: this.state.team_size,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showAddOn: false,
          });
          // this.props.dispatch(
          //   showReferral({
          //     title: "Want to extend your plan for free?",
          //     content:
          //       "Invite your friends and get 7 days of Premium Plan added",
          //   })
          // );
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  questionPaperTopUPCallBack = (data) => {
    if (data.hidePopUp) {
      this.setState({
        showTopUp: !data.hidePopUp,
      });
    }
  };

  render() {
    let que_number_start_pt = this.state.pageNo * 10 - 10; // ques number for first question of every page say 1 for page one 11 for page 2
    let easymcq = que_number_start_pt;
    let mediummcq = que_number_start_pt;
    let hardmcq = que_number_start_pt;
    let true_false = que_number_start_pt;
    let fillups = que_number_start_pt;
    let descriptive = que_number_start_pt;
    let is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;

    return (
      <React.Fragment>
        <Prompt
          when={this.state.leavePageEvent}
          message={() =>
            trackEvent("Leaving Generate Question Page", {
              source: this.state.source,
            })
          }
        />
        {this.props.user.email ? (
          <ProductFruits
            workspaceCode="EdZdZ5lp8w9caz0m"
            language="en"
            user={{
              username: this.props.user.name,
              email: this.props.user.email,
              firstname: this.props.user.first_name,
              lastname: this.props.user.last_name,
              signUpAt: this.props.user.sign_up_at,
            }}
          />
        ) : (
          ""
        )}

        <Tooltip title="Coming Soon" id="global" />
        <div className="height-cover bg-e5">
          {/* <div className="row countdwn-bt">
            <p className="countdown_msg_bt">
              Click here to user Blooms Taxanomy
              <Link
                className="learnmorebtn-bt"
                to={{
                  pathname:
                    "https://www.prepai.io/higher-order-thinking-question-maker/",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Link>
            </p>
          </div> */}
          {/* <div class="row countdwn-bt">
            <div class="col-md-6 text_msg">
              <p class="countdown_msg_bt">
                Rolling Out Soon: Bloom’s Taxonomy on PrepAI - Generate higher
                order thinking questions
                <a href="#" class="w-btn us-btn-style_3 learnmorebtn-bt">
                  {" "}
                  Learn More
                </a>
              </p>
            </div>
          </div> */}

          {this.state.showBar && (
            <div className="row col-md-12 countdwn-home-bt">
              <div className="col-md-8">
                <p className="countdown_msg_bt">
                  Generate higher order thinking questions as per the Bloom's
                  Taxonomy
                </p>
              </div>
              <div className="col-md-4 countdown_msg_bt">
                <span
                  onClick={(e) => {
                    this.setState({ showBar: !this.state.showBar });
                  }}
                  style={{ padding: "15px", cursor: "pointer" }}
                >
                  Dismiss
                </span>
                {/* <Link
                  className="learnmorebtn-bt"
                  // to={{ pathname: "https://hots.prepai.io/" }}
                  to={{ pathname: "http://localhost:3000/generate-questions" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Let's go
                </Link> */}
                <span
                  className="learnmorebtn-bt"
                  onClick={this.redirectToBloom}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Let's go{" "}
                </span>
              </div>
            </div>
          )}
          <div className="bg--overlay--top"></div>

          {this.state.showProgressBar && (
            <div>
              <div className="overlay"></div>
              <ProgressBar
                progress={this.state.progress_percent}
                content_id={this.state.contentId}
              ></ProgressBar>
            </div>
          )}

          <div className="container mt-50 mb-4 position-relative">
            <div>
              {/* <Toggle blooms={false} /> */}
              <ToggleForGQ tab="Standard" />
            </div>
            <div className="generate--bg--title">
              <h3>Generate Question Papers in minutes</h3>
              <span>Most Advanced Test Generator</span>
            </div>
            <div className="question-generate-tab-outer maxw-820">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group custom-login-field">
                  <label
                    htmlFor="titleofQuestion"
                    className="custom-login-field--label"
                  >
                    Enter a Title for Question Paper
                  </label>
                  <div className="position-relative">
                    <input
                      className={`custom-login-field--inputs form-control px-2 ${
                        this.state.titleofQuestion_emsg != ""
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      name="titleofQuestion"
                      value={this.state.titleofQuestion}
                      onChange={this.handleTitleChange}
                      id="titleofQuestion"
                      aria-describedby="textHelp"
                      placeholder="e.g. Chapter Name, Topic Name, Section, etc."
                      onClick={() =>
                        this.setState({
                          activity_count: this.state.activity_count + 1,
                        })
                      }
                    />
                    {this.state.titleofQuestion_emsg && (
                      <span className="text-danger">
                        {this.state.titleofQuestion_emsg}
                      </span>
                    )}
                  </div>
                </div>
                <div className="navtab--outer">
                  <label htmlFor="" className="custom-login-field--label">
                    Content Source
                  </label>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${
                          this.state.defaultActiveTab == "wiki" ? "active" : ""
                        }`}
                        id="wiki-tab"
                        data-toggle="tab"
                        href="#wiki"
                        role="tab"
                        aria-controls="wiki"
                        aria-selected="true"
                        onClick={(e) => (
                          this.tabChanged(1),
                          trackEvent("Topic Search Tab Clicked"),
                          this.setState({
                            activity_count: this.state.activity_count + 1,
                          })
                        )}
                      >
                        Topic Search
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${
                          this.state.defaultActiveTab == "custom-tab"
                            ? "active"
                            : ""
                        }`}
                        id="custom-tab"
                        data-toggle="tab"
                        href="#custom"
                        role="tab"
                        aria-controls="custom"
                        aria-selected="false"
                        onClick={(e) => (
                          this.tabChanged(2),
                          trackEvent("Type or Paste Text Tab Clicked"),
                          this.setState({
                            activity_count: this.state.activity_count + 1,
                          })
                        )}
                      >
                        Type or Paste Text
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      style={{ position: "relative" }}
                      role="presentation"
                    >
                      {/* <a
                        href="#"
                        className={`nav-link ${
                          this.state.defaultActiveTab == "video-tab"
                            ? "active"
                            : ""
                        }`}
                        data-tip
                        data-for="global"
                        onClick={(e) => e.preventDefault()}
                      >
                        Video
                      </a> */}
                      <a
                        className="nav-link"
                        id="VideoUrl-tab"
                        data-toggle="tab"
                        href="#VideoUrl"
                        role="tab"
                        aria-controls="VideoUrl"
                        aria-selected="false"
                        onClick={(e) => (
                          this.tabChanged(3),
                          trackEvent("Video Tab Clicked"),
                          this.setState({
                            activity_count: this.state.activity_count + 1,
                          })
                        )}
                      >
                        Video
                        {is_new_pricing === 1 && (
                          <img
                            src="img/crown.jpg"
                            alt="icon"
                            width="30"
                            height="30"
                            style={{
                              position: "absolute",
                              top: "-4px",
                              marginLeft: "-7px",
                            }}
                          />
                        )}
                      </a>
                      {/*<span
                        className="badge badge-danger"
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "1px",
                        }}
                      >
                        New
                      </span> */}
                    </li>
                    <li
                      className="nav-item"
                      style={{ position: "relative" }}
                      role="presentation"
                    >
                      {/*<a
                        href="#"
                        className={`nav-link ${
                          this.state.defaultActiveTab == "upload-tab"
                            ? "active"
                            : ""
                        }`}
                        data-tip
                        data-for="global"
                        onClick={(e) => e.preventDefault()}
                      >
                        Upload PDF / Docx
                      </a> */}
                      <a
                        className="nav-link"
                        id="PDF-tab"
                        data-toggle="tab"
                        href="#PDF"
                        role="tab"
                        aria-controls="PDF"
                        aria-selected="false"
                        //style={{ paddingLeft: "8px", textAlign: "left" }}
                        onClick={(e) => (
                          this.tabChanged(4),
                          trackEvent("Upload PDF/Docx Tab Clicked"),
                          this.setState({
                            activity_count: this.state.activity_count + 1,
                          })
                        )}
                      >
                        <span style={{ position: "relative", left: "-11px" }}>
                          Upload Document
                        </span>
                        {is_new_pricing === 1 && (
                          <img
                            src="img/crown.jpg"
                            alt="icon"
                            width="30"
                            height="30"
                            style={{
                              position: "absolute",
                              top: "-2px",
                              right: "10px",
                            }}
                          />
                        )}
                      </a>
                      {/*<span
                        className="badge badge-danger"
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "0px",
                        }}
                      >
                        New
                      </span> */}
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        this.state.defaultActiveTab == "custom-tab"
                          ? "active show"
                          : ""
                      }`}
                      id="custom"
                      role="tabpanel"
                      aria-labelledby="custom-tab"
                    >
                      <div className="form-group custom-login-field">
                        <label
                          htmlFor="contentForQuestions"
                          className="custom-login-field--label"
                        >
                          Type/Paste your Content here
                        </label>
                        <div className="position-relative">
                          <textarea
                            className={`custom-login-field--inputs form-control px-2 resize--none${
                              this.state.showWordLimit
                                ? "border border-danger"
                                : ""
                            }`}
                            id="contentForQuestions"
                            name="contentForQuestions"
                            value={this.state.contentForQuestions}
                            onChange={this.handleChange}
                            aria-describedby="textareaHelp"
                            rows="7"
                            placeholder="Type/Paste your content (minimum 50 words) here to generate questions..."
                            onClick={() =>
                              this.setState({
                                activity_count: this.state.activity_count + 1,
                              })
                            }
                          ></textarea>
                          {this.state.showWordLimit && (
                            <span className="word_limit">
                              Please enter at least 50 words
                            </span>
                          )}
                        </div>
                        {this.state.currentActiveTab === 2 && (
                          <CustomQuestions />
                        )}
                        {/* Generate Questions Button */}
                        <div className="btn-flex100">
                          {/* Filter Section */}
                          {/* <Filter/> */}

                          <button
                            type="submit"
                            className="btn--submit"
                            onClick={this.handleGenerateQuestion}
                            disabled={
                              this.state.contentForQuestions ? false : true
                            }
                          >
                            Generate Questions
                          </button>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        this.state.defaultActiveTab == "upload-tab"
                          ? "active show"
                          : ""
                      }`}
                      id="PDF"
                      role="tabpanel"
                      aria-labelledby="PDF-tab"
                    >
                      {/* Custom dropzone code goes here */}
                      {/* <div id="dropzone">
                          <form action="/upload" className="dropzone needsclick" id="demo-upload">
                              <div className="text-center">
                                  <div className="dz-message needsclick">

                                  </div>
                                  <img src="./img/dropzone-icon.png" className="d-block mx-auto" alt="">
                                  <div className="d-block my-3">
                                      <button className="btn--submit px-4 py-1 font--400">Choose File</button>
                                  </div>
                                  <div className="dropfile--text">
                                      or drop file here
                                  </div>
                              </div>
                              <span className="plus">+</span>
                          </form>
                        </div> */}
                      {/* <div className="clsbox-1" runat="server"  >
                            <div className="dropzone clsbox" id="mydropzone">

                            </div>
                        </div> */}
                      <div className="video--content">
                        {/*<div className="text-center">
                          <img
                            src={`${process.env.REACT_APP_PROJECT_URL}/img/dropzone-icon.png`}
                            alt="upload"
                          />
                        </div> */}
                        <div
                          className={`m-auto text-center video--content-font ${
                            !_.isEmpty(this.props.user) &&
                            !this.props.user.plan_details
                              .upload_pdf_doc_is_allowed
                              ? "sm-disabled"
                              : ""
                          }`}
                          style={{ width: "70%" }}
                          onClick={this.updateActivityCount}
                        >
                          <Dropzone
                            accept=".pdf, .PDF, .docx, .doc"
                            maxFiles={1}
                            canCancel={true}
                            data-tip
                            inputContent="Drag Files or Click to Browse (.pdf, .docx, .doc)"
                            onChangeStatus={this.handlePdfDocFileStatusChange}
                            getUploadParams={() => ({
                              url: "https://httpbin.org/post",
                            })}
                            // InputComponent={Input}
                            getFilesFromEvent={this.getDocPdfFromEvent}
                            styles={{
                              dropzone: {
                                // minHeight: 150,
                                maxHeight: 250,
                                wordBreak: "break-word",
                                zIndex: "0",
                              },
                            }}
                            disabled={
                              !_.isEmpty(this.props.user) &&
                              !this.props.user.plan_details
                                .upload_pdf_doc_is_allowed
                            }
                          />
                          {this.state.pdfDocFile && (
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "10px",
                                alignItems: "center",
                                marginTop: "1rem",
                              }}
                            >
                              {!this.state.isCustomPages ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#49c0a0",
                                  }}
                                  onClick={this.customPageHandler}
                                >
                                  Click here to select custom pages.
                                </div>
                              ) : (
                                <div className="get-doc-pages">
                                  {/* <label htmlFor="page-no">
                                {this.state.docPageStatement}
                              </label> */}
                                  <div className="notify-tool-tip">
                                    <i
                                      style={{
                                        color: "#007bff",
                                        marginTop: "0.25rem",
                                        cursor: "pointer",
                                      }}
                                      data-for="iota"
                                      className="fas fa-info-circle"
                                    ></i>
                                    <span className="hoverText">
                                      Type page numbers and/or page ranges,
                                      seprated by commas. For example:
                                      1,3,5-12,14.
                                    </span>
                                  </div>
                                  <input
                                    id="page-no"
                                    type="text"
                                    onChange={this.handleDocPage}
                                    value={this.state.docPage}
                                    placeholder="1,3,5-12,14"
                                  />
                                  {this.state.docPage && (
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        cursor: "pointer",
                                      }}
                                      onClick={this.handleClearCustomPages}
                                    >
                                      x
                                    </span>
                                  )}
                                </div>
                              )}
                              <span
                                style={{ height: "1.2rem" }}
                                className="badge badge-info"
                              >
                                BETA
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Custom dropzone code goes here */}
                      {this.state.currentActiveTab === 4 && <CustomQuestions />}
                      <div className="btn-flex100">
                        {/* Filter Section */}
                        {/* <Filter/> */}

                        <button
                          type="submit"
                          className="btn--submit"
                          onClick={this.handleGenerateQuestionFromPdfDoc}
                          disabled={
                            this.state.pdfDocFile == null ? true : false
                          }
                        >
                          Generate Questions
                        </button>
                      </div>

                      {/* Button for testing the referral popup*/}
                      {/* <button onClick={this.handlePopupOpen}> Show </button> */}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        this.state.defaultActiveTab == "video-tab"
                          ? "active show"
                          : ""
                      }`}
                      id="VideoUrl"
                      role="tabpanel"
                      aria-labelledby="VideoUrl-tab"
                    >
                      {/* Video Content Goes Here */}
                      <div className="video--content">
                        <div
                          className="m-auto text-center video--content-font"
                          style={{ width: "70%" }}
                        >
                          <label htmlFor=""> Enter your Video URL below </label>
                          {/*<p className="finger-pointing-down"></p> */}
                          <div className="videoImg-content">
                            <img
                              src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                              alt="videoImage"
                            />
                            <input
                              type="text"
                              className={`voideourl--text ${
                                !_.isEmpty(this.props.user) &&
                                !this.props.user.plan_details.video_is_allowed
                                  ? "sm-disabled"
                                  : ""
                              }`}
                              name="videourl"
                              data-tip
                              data-for="upload_video"
                              value={this.state.videourl}
                              onChange={this.handleChange}
                              placeholder="Paste Video URL from Youtube"
                              disabled={
                                this.state.isUploaded
                                  ? true
                                  : false ||
                                    (!_.isEmpty(this.props.user) &&
                                      !this.props.user.plan_details
                                        .video_is_allowed)
                              }
                              onClick={() =>
                                this.setState({
                                  activity_count: this.state.activity_count + 1,
                                })
                              }
                            />
                            <Tooltip
                              id="upload_video"
                              title="You can either use YouTube URL or Upload video"
                            />
                          </div>

                          {this.state.videourl && (
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "10px",
                                alignItems: "center",
                                position: "relative",
                                marginTop: "1rem",
                              }}
                            >
                              {!this.state.isCustomDuration ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#49c0a0",
                                  }}
                                  onClick={this.isCustomDurationHandler}
                                >
                                  Click here to select Duration.
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TimeInput
                                      id="startTime"
                                      timeStampHandler={(secs) =>
                                        this.startTimeStampHandler(secs)
                                      }
                                      startTyping={this.startTypingTime}
                                      isValid={this.state.timeInputMsg}
                                    />
                                    to
                                    <TimeInput
                                      id="endTime"
                                      timeStampHandler={(secs) =>
                                        this.endTimeStampHandler(secs)
                                      }
                                      startTyping={this.startTypingTime}
                                      isValid={this.state.timeInputMsg}
                                    />
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        cursor: "pointer",
                                      }}
                                      onClick={this.handleClearCustomDuration}
                                    >
                                      x
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      color: "#dc3545",
                                      fontWeight: "500",
                                      marginTop: "6px",
                                      fontSize: "13px",
                                    }}
                                    className="text-left"
                                  >
                                    {this.state.timeInputMsg}
                                  </div>
                                </div>
                              )}
                              <span
                                style={{
                                  height: "1.2rem",
                                  position: "absolute",
                                  right: "-4rem",
                                  top: "0.2rem",
                                }}
                                className="badge badge-info"
                              >
                                BETA
                              </span>
                            </div>
                          )}
                        </div>
                        <h5 className="mt-2 mb-1 text-center videoordiv">
                          <span>OR</span>
                        </h5>
                        <div
                          className="m-auto text-center video--content-font"
                          style={{ width: "70%" }}
                        >
                          <div className="videoImg-content">
                            <label htmlFor="" className="mt-1">
                              Upload Video
                            </label>
                            <div
                              className={`${
                                !_.isEmpty(this.props.user) &&
                                !this.props.user.plan_details.video_is_allowed
                                  ? "sm-disabled"
                                  : ""
                              }`}
                              onClick={this.updateActivityCount}
                            >
                              <Dropzone
                                name="video-dropzone"
                                accept="video/*"
                                maxFiles={1}
                                canCancel={true}
                                data-tip
                                data-for="upload_video"
                                inputContent="Drag Files or Click to Browse(.mp4, .avi, .mov, .wmv)"
                                onChangeStatus={this.handleFileStatusChange}
                                getUploadParams={() => ({
                                  url: "https://httpbin.org/post",
                                })}
                                // InputComponent={Input}
                                getFilesFromEvent={this.getFilesFromEvent}
                                disabled={
                                  this.state.videourl
                                    ? true
                                    : false ||
                                      (!_.isEmpty(this.props.user) &&
                                        !this.props.user.plan_details
                                          .video_is_allowed)
                                }
                                styles={{
                                  dropzone: {
                                    // minHeight: 150,
                                    maxHeight: 250,
                                    wordBreak: "break-word",
                                    zIndex: "0",
                                  },
                                }}
                              />

                              {this.state.videoFile && (
                                <div
                                  style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    position: "relative",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                  }}
                                >
                                  {!this.state.isCustomDuration ? (
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "#49c0a0",
                                      }}
                                      onClick={this.isCustomDurationHandler}
                                    >
                                      Click here to select Duration.
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <TimeInput
                                          id="startTime"
                                          timeStampHandler={(secs) =>
                                            this.startTimeStampHandler(secs)
                                          }
                                          startTyping={this.startTypingTime}
                                          isValid={this.state.timeInputMsg}
                                        />
                                        to
                                        <TimeInput
                                          id="endTime"
                                          timeStampHandler={(secs) =>
                                            this.endTimeStampHandler(secs)
                                          }
                                          startTyping={this.startTypingTime}
                                          isValid={this.state.timeInputMsg}
                                        />
                                        <span
                                          style={{
                                            fontWeight: "700",
                                            cursor: "pointer",
                                          }}
                                          onClick={
                                            this.handleClearCustomDuration
                                          }
                                        >
                                          x
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: "500",
                                          marginTop: "6px",
                                          fontSize: "13px",
                                        }}
                                        className="text-left"
                                      >
                                        {this.state.timeInputMsg}
                                      </div>
                                    </div>
                                  )}
                                  <span
                                    style={{
                                      height: "1.2rem",
                                      position: "absolute",
                                      right: "-4rem",
                                      top: "0.2rem",
                                    }}
                                    className="badge badge-info"
                                  >
                                    BETA
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Video Content Goes Here */}
                      {this.state.currentActiveTab === 3 && <CustomQuestions />}
                      <div className="btn-flex100 video-section">
                        {/*<button
                          type="button"
                          className="btn--submit"
                          onClick={this.handleGenerateQuestionFromVideo}
                          disabled={
                            this.state.videourl || this.state.isUploaded
                              ? false
                              : true
                          }
                        >
                          Extract Content
                        </button> */}

                        {/* Filter Section */}
                        {/* <Filter/> */}
                        {this.videoExtractButtonContent()}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        this.state.defaultActiveTab == "wiki"
                          ? "active show"
                          : ""
                      }`}
                      id="wiki"
                      role="tabpanel"
                      aria-labelledby="wiki-tab"
                    >
                      <div className="form-group custom-login-field">
                        <div className="position-relative">
                          <label htmlFor="searchTopic">
                            Search Your Topic Here
                          </label>
                          <Select
                            name="searchTopic"
                            id="searchTopic"
                            onInputChange={this.onInputChange}
                            onChange={(option) =>
                              this.handleWikiTopicSelect("searchTopic", option)
                            }
                            onFocus={this.handleWikiTopicFocus}
                            onBlur={this.handleWikiTopicBlur}
                            onClick={this.handleWikiTopicFocus}
                            value={this.state.tmpselectedWikiTopic}
                            options={this.state.options}
                            placeholder={`Enter a Topic Name`}
                          />
                          <textarea
                            className={`custom-login-field--inputs form-control mt-3 px-2 resize--none ${
                              this.state.searchTopicWordLimit
                                ? "border border-danger"
                                : ""
                            }`}
                            id="wikiContent"
                            name="wikiContent"
                            aria-describedby="textareaHelp"
                            onChange={this.handleChange}
                            rows="7"
                            value={this.state.wikiContent}
                            disabled={this.state.searchTopic ? false : true}
                            placeholder="Extracted Content will be shown here. Edit, Remove or Add More Content."
                            onClick={() =>
                              this.setState({
                                activity_count: this.state.activity_count + 1,
                              })
                            }
                          ></textarea>
                          {this.state.searchTopicWordLimit && (
                            <span className="word_limit">
                              Please enter at least 50 words
                            </span>
                          )}
                        </div>

                        {this.state.currentActiveTab === 1 && (
                          <CustomQuestions />
                        )}

                        <div className="btn-flex100">
                          {/* Filter Section */}
                          {/* <Filter/> */}
                          {/* {this.showSelectedFilter()} */}
                          <button
                            type="submit"
                            className="btn--submit"
                            id="wiki-submit-button"
                            onClick={this.handleGenerateQuestionForWiki}
                            disabled={this.state.wikiContent ? false : true}
                          >
                            Generate Questions
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div id="scroll-to-div"></div>
          </div>
        </div>

        {this.state.questionPaperGenerated &&
          this.props.user.update_profile_index == 0 && (
            <div className="section--padding bg-e5" id="generated_questions">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 mb-4" style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col-md-8">
                        <p
                          className="section-generated-title"
                          style={{ marginLeft: "29.5%" }}
                        >
                          Generated Questions
                        </p>
                      </div>
                      <div className="col-md-4 front-action-buttons">
                        <li
                          className="nav-item dropdown fl-center col-md-2"
                          style={{
                            justifyContent: "flex-end",
                            paddingRight: "5%",
                          }}
                        >
                          <a
                            className="nav-link dropdown-toggle dropdown-title-front"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {/* <span className="dinline-block mr-2">
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.4118 5.07588C11.4118 7.52341 9.44941 9.48593 7.00016 9.48593C4.55174 9.48593 2.5885 7.52341 2.5885 5.07588C2.5885 2.62836 4.55174 0.666672 7.00016 0.666672C9.44941 0.666672 11.4118 2.62836 11.4118 5.07588ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2325 13.6668 14.4991C13.6668 16.7667 10.5957 17.3333 7.00016 17.3333Z"
                            fill="#526892"
                          />
                        </svg>
                      </span> */}
                            Conduct Test
                            {/* <span className="ml-1 arr-stroke">
                        <svg
                          width="15"
                          height="9"
                          viewBox="0 0 15 9"
                          fill="none"
                        >
                          <path
                            d="M14 1L7.5 8L1 1"
                            stroke="white"
                            strokeLinecap="square"
                          />
                        </svg>
                      </span> */}
                          </a>
                          <div
                            className="dropdown-menu navdrop--menu dropdown-main"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              to="#"
                              onClick={this.beforeAssessment}
                              className="dropdown-item"
                            >
                              For Self
                            </Link>
                            <Link
                              to="#"
                              onClick={this.handleCreateTest}
                              className={`dropdown-item ${
                                !_.isEmpty(this.props.user) &&
                                !this.props.user.plan_details
                                  .is_group_assessment
                                  ? "sm-disabled"
                                  : ""
                              }`}
                            >
                              For Others
                              {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )}
                            </Link>
                            {/* <Link
                          className="dropdown-item"
                          to={{
                            pathname: "/question-paper-preview-bloom",
                            state: this.state.questions[0],
                          }}
                          onClick={(e) =>
                            trackEvent("Preview Question Paper Clicked", {
                              source: this.state.source,
                            })
                          }
                        >
                          Preview Question Paper
                        </Link> */}
                          </div>
                        </li>
                        {/* <div className="col-md-2">
                          <button
                            onClick={() => this.beforeAssessment()}
                            type="button"
                            role="button"
                            className="preview--paper--btn"
                            style={{
                              width: "max-content",
                            }}
                          >
                            Self Assessment
                          </button>
                        </div> */}
                        {/* <div className="col-md-2">
                        <button
                          onClick={() => this.handleCreateTest()}
                          type="button"
                          role="button"
                          className="preview--paper--btn"
                          style={{
                            width: "max-content",
                            marginLeft: "3rem"
                          }}
                        >
                          Conduct Test
                        </button>
                      </div> */}
                        <div className="">
                          <Link
                            type="button"
                            role="button"
                            style={{ textDecoration: "none" }}
                            className="preview--paper--btn"
                            to={{
                              pathname: "/question-paper-preview",
                              state: this.state.questions[0],
                            }}
                            onClick={(e) =>
                              trackEvent("Preview Question Paper Clicked", {
                                source: this.state.source,
                              })
                            }
                          >
                            Preview & Download
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="left-widget-area">
                      <div className="category-widget">
                        <p>Categories</p>
                        <div className="custom-nav-pills-column">
                          <div
                            className="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <a
                              className="nav-link active"
                              id="v-pills-easy-tab"
                              data-toggle="pill"
                              href="#v-pills-easy"
                              role="tab"
                              aria-controls="v-pills-easy"
                              aria-selected="true"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "easymcq", 1)
                              }
                            >
                              Easy MCQ
                              <span>{this.state.ques_count.easyCount}</span>
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-medium-tab"
                              data-toggle="pill"
                              href="#v-pills-medium"
                              role="tab"
                              aria-controls="v-pills-medium"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "mediummcq", 2)
                              }
                            >
                              Medium MCQ
                              <span>{this.state.ques_count.mediumCount}</span>
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-hard-tab"
                              data-toggle="pill"
                              href="#v-pills-hard"
                              role="tab"
                              aria-controls="v-pills-hard"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "hardmcq", 3)
                              }
                            >
                              Hard MCQ
                              <span>{this.state.ques_count.hardCount}</span>
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-truefalse-tab"
                              data-toggle="pill"
                              href="#v-pills-truefalse"
                              role="tab"
                              aria-controls="v-pills-truefalse"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "truefalse", 4)
                              }
                            >
                              True / False
                              <span>
                                {this.state.ques_count.trueFalseCount}
                              </span>
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-Descriptive-tab"
                              data-toggle="pill"
                              href="#v-pills-Descriptive"
                              role="tab"
                              aria-controls="v-pills-Descriptive"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "descriptive", 5)
                              }
                            >
                              Descriptive
                              <span>
                                {this.state.ques_count.descriptiveCount}
                              </span>
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-Fillups-tab"
                              data-toggle="pill"
                              href="#v-pills-Fillups"
                              role="tab"
                              aria-controls="v-pills-Fillups"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "fillups", 6)
                              }
                            >
                              Fillups
                              <span>{this.state.ques_count.fillUpsCount}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <button
                        className={` add-quetsion--btn w-100 ${
                          !_.isEmpty(this.props.user) &&
                          !this.props.user.plan_details.is_add_question
                            ? "sm-disabled"
                            : ""
                        }`}
                        // className="add-quetsion--btn w-100"
                        role="button"
                        type="button"
                        // onClick={this.addquestion}
                        onClick={this.handleAddQuestionOnClick}
                        disabled={
                          !_.isEmpty(this.props.user) &&
                          !this.props.user.plan_details.is_add_question
                        }
                      >
                        <svg
                          className="mr-2"
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                        >
                          <path
                            d="M8.38021 1V14M1.8125 7.5H14.9479"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                        </svg>
                        Add Question
                        {is_new_pricing === 1 && (
                          <img
                            src="img/crown.jpg"
                            alt="icon"
                            width="30"
                            height="30"
                            style={{
                              position: "absolute",
                              marginTop: "-12px",
                              marginLeft: "-5px",
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-easy"
                        role="tabpanel"
                        aria-labelledby="v-pills-easy-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.easyCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 1) {
                              easymcq = easymcq + 1;
                              return (
                                <EasyMCQ
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={easymcq}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.addeasymcq == true ? (
                            <div>
                              <br />
                              <Addeasymcq
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></Addeasymcq>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                        {/* Easy MCQ Pagination Section */}
                        {this.state.ques_count.easyCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a
                                      className={
                                        this.state.pageNo == 1
                                          ? "sm-disabled"
                                          : ""
                                      }
                                    >
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-medium"
                        role="tabpanel"
                        aria-labelledby="v-pills-medium-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.mediumCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 2) {
                              mediummcq = mediummcq + 1;
                              return (
                                <MediumMCQ
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={mediummcq}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.addmediummcq == true ? (
                            <div>
                              <br />
                              <Addmediummcq
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></Addmediummcq>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>

                        {/* Medium MCQ Pagination Section */}
                        {this.state.ques_count.mediumCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-hard"
                        role="tabpanel"
                        aria-labelledby="v-pills-hard-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.hardCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 3) {
                              hardmcq = hardmcq + 1;
                              return (
                                <HardMCQ
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={hardmcq}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.addhardmcq == true ? (
                            <div>
                              <br />
                              <Addhardmcq
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></Addhardmcq>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>

                        {/* Hard MCQ Pagination Section */}
                        {this.state.ques_count.hardCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-truefalse"
                        role="tabpanel"
                        aria-labelledby="v-pills-truefalse-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.trueFalseCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 4) {
                              true_false = true_false + 1;
                              return (
                                <TrueFalse
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={true_false}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.addtruefalse == true ? (
                            <div>
                              <br />
                              <Truefalse
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></Truefalse>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>

                        {/* True/False Pagination Section */}
                        {this.state.ques_count.trueFalseCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-Descriptive"
                        role="tabpanel"
                        aria-labelledby="v-pills-Descriptive-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.descriptiveCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 5) {
                              descriptive = descriptive + 1;
                              return (
                                <Descriptive
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={descriptive}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.adddescriptive == true ? (
                            <div>
                              <br />
                              <AddDescriptive
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></AddDescriptive>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                        {/* Descriptive Type Pagination Section */}
                        {this.state.ques_count.descriptiveCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-Fillups"
                        role="tabpanel"
                        aria-labelledby="v-pills-Fillups-tab"
                      >
                        <div className="question-wrapper--outer">
                          {this.state.ques_count.fillUpsCount == 0 && (
                            <p>No Questions Formed!</p>
                          )}
                          {this.state.questions.map((question) => {
                            if (question.category_type == 6) {
                              fillups = fillups + 1;
                              return (
                                <FillUpsMCQ
                                  key={`question_${question.question_id}`}
                                  question={question}
                                  count={fillups}
                                  handleQuestionUpdate={
                                    this.handleQuestionUpdate
                                  }
                                  // data={{ source: this.state.source }}
                                />
                              );
                            }
                          })}
                          {this.state.addfillups == true ? (
                            <div>
                              <br />
                              <Fillups
                                questions={this.state.questions}
                                handlestate={this.handlestateforaddquestion}
                                parentCallback={this.handleCallback}
                                discardquestion={this.handlediscard}
                              ></Fillups>{" "}
                            </div>
                          ) : (
                            " "
                          )}
                        </div>

                        {/* Fillups Pagination Section */}
                        {this.state.ques_count.fillUpsCount != 0 && (
                          <div className="each-question-wrapper-box-pagination">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <p className="showing-page-results">
                                  Showing {this.state.pageNo}-
                                  {this.state.totalPages} of{" "}
                                  {this.state.totalPages} items
                                </p>
                              </div>
                              <div className="col-md-8">
                                <ul className="list-unstyled-pagination">
                                  <li
                                    className={`prev-navigation show-pointer ${
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.previousPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                  <li className="page-count-navigation">
                                    <a>{this.state.pageNo}</a>
                                  </li>
                                  <li
                                    className={`next-navigation show-pointer ${
                                      this.state.pageNo == this.state.totalPages
                                        ? "sm-disabled"
                                        : ""
                                    }`}
                                    onClick={this.nextPage}
                                  >
                                    <a>
                                      <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                          stroke="#B7CCEB"
                                          strokeWidth="1.3"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <SweetAlert
          show={this.state.showRatingAlert}
          showConfirm
          showCloseButton
          style={{ zIndex: 99999999 }}
          title={
            <>
              <div className="swal-titile">
                <h4>
                  <span>Your Question Paper is being Generated.</span>
                  <br />
                  <span>
                    Meanwhile, what do you think of your recent Question Paper?{" "}
                  </span>
                </h4>
              </div>
              <div className="rate--question large-star1 mt-4">
                <span className="feedbackLabel">
                  <span id="labelFeedbackLabel"></span>
                </span>
                <div className="line-height-0 d-flex justify-content-center pt-4">
                  <div id="bad" className="emoji-scale">
                    <span className="describeEmoji">Bad</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 1 ? "fas" : "far"
                      } fa-frown highlight ${this.state.highlightBadEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 1,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: null,
                          highlightBadEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="neutral" className="emoji-scale">
                    <span className="describeEmoji">Neutral</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 2 ? "fas" : "far"
                      } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 2,
                          highlightBadEmoji: null,
                          highlightGoodEmoji: null,
                          highlightNeutralEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="Good" className="emoji-scale">
                    <span className="describeEmoji">Good</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 3 ? "fas" : "far"
                      } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 3,
                          highlightBadEmoji: null,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </>
          }
          confirmBtnText="Submit"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={this.handleRatingPromptCloseEvent}
          onOutsideClick={this.handleRatingPromptCloseEvent}
          onConfirm={this.handleQPRating}
          onCancel={this.handleRatingPromptCloseEvent}
        ></SweetAlert>
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showAlert}
          showConfirm
          title={
            <div className="swal-title">
              <h2>Please Read Carefully</h2>
              <hr />
              <ol>
                <li className="instruction-points">
                  PrepAI only supports content in the English language.
                </li>
                <li className="instruction-points">
                  PrepAI is best suited for subjective kind of content for now.
                </li>
                <li className="instruction-points">
                  PrepAI doesn't consider mathematical equations, chemical
                  reactions, numerical, tabular data, diagrams yet for question
                  generation.
                </li>
              </ol>
            </div>
          }
          confirmBtnText="OK"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showAlert: false })}
          onOutsideClick={() => this.setState({ showAlert: false })}
          onConfirm={() => this.setState({ showAlert: false })}
          onCancel={() => this.setState({ showAlert: false })}
        />
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPremiumPrompt}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">{this.state.premiumPromptText}</p>
              <Link className="btn prompt-plan-btn" to="/plans">
                Upgrade Plan
              </Link>
              {/* <span style={{ margin: "20px", fontSize: "20px" }}>OR</span>
              <Link className="btn prompt-referral-btn" to="/refer-prepai">
                Refer PrepAI
              </Link> */}
            </div>
          }
          onEscapeKey={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onOutsideClick={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onConfirm={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onCancel={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
        />
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showUpdateProfilePopUp}
          showConfirm
          title="Please complete your profile to continue"
          confirmBtnText={
            this.props.user.update_profile_index == 3 ? "Finish" : "Continue"
          }
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
          onOutsideClick={() =>
            this.setState({ showUpdateProfilePopUp: false })
          }
          onConfirm={this.UpdateProfileSubmit}
          onCancel={() => this.setState({ showUpdateProfilePopUp: false })}
          style={{ overflow: "initial", marginTop: "150px" }}
        >
          <form>
            {this.props.user.update_profile_index == 1 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  What best describes your role?*
                </label>

                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="profession"
                    className="source_from"
                    required
                    value={this.state.profession}
                    onChange={this.handleUpdateProfileChange}
                  >
                    <option value="">Select</option>
                    {profession().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}{" "}
            {this.props.user.update_profile_index == 2 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  How did you discover PrepAI?*
                </label>
                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="source_from"
                    className="source_from"
                    required
                    onChange={this.handleUpdateProfileChange}
                    value={this.state.source_from}
                    disabled={this.state.referralActive}
                  >
                    <option value="">Select</option>

                    {findUsList().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.props.user.update_profile_index == 3 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  Update your contact number*
                </label>
                <div
                  className="position-relative"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <PhoneInput
                    country={this.state.data.country_code}
                    countryCodeEditable={false}
                    placeholder="Enter phone number"
                    name="formatted_phone_number"
                    value={this.state.data.formatted_phone_number}
                    onChange={this.handleOnChange}
                    buttonStyle={{ backgroundColor: "transparent" }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                position: "relative",
                marginLeft: "98px",
                marginTop: "18px",
              }}
            >
              {this.state.source_from === "Others" &&
                this.props.user.update_profile_index == 2 && (
                  <div
                    className="form-group form-input"
                    // style={{ position: "absolute", left: "0", top: "-35px" }}
                  >
                    <label htmlFor="source_from_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="source_from_other"
                      id="source_from_other"
                      value={this.state.source_from_other}
                      onChange={this.handleChange}
                      required
                      style={{ marginLeft: "35px", fontSize: "larger" }}
                    />
                  </div>
                )}

              {this.state.profession === "Others" &&
                this.props.user.update_profile_index == 1 && (
                  <div
                    className="form-group form-input"
                    // style={{
                    //   marginLeft:
                    //     this.state.source_from === "Others" && "17px",
                    //   position: "absolute",
                    //   right: "5px",
                    //   top: "-35px",
                    // }}
                  >
                    <label htmlFor="profession_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="profession_other"
                      id="profession_other"
                      value={this.state.profession_other}
                      onChange={this.handleChange}
                      required
                      style={{ marginLeft: "35px", fontSize: "larger" }}
                    />
                  </div>
                )}
            </div>
          </form>
          {this.state.showLoader ? (
            <div className="spinner-border mt-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showEditContentPopUp}
          showConfirm={false}
          title={
            <div className="swal-title">
              <h4>
                Are you sure you want to proceed with this prefilled content?
              </h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <div>
                  <button
                    type="button"
                    className="btn pp-btn"
                    onClick={this.handleGenerateAnyway}
                  >
                    Yes, generate anyway
                  </button>
                </div>
                <div style={{ paddingLeft: "100px" }}>
                  <button
                    type="button"
                    className="btn ppn-btn"
                    onClick={this.handleUpdateContent}
                  >
                    No, update the content
                  </button>
                </div>
              </div>
            </div>
          }
          // confirmBtnText="OK"
          // confirmBtnStyle={{ fontSize: "16px" }}
          // confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showEditContentPopUp: false })}
          onOutsideClick={() => this.setState({ showEditContentPopUp: false })}
          onConfirm={() => this.setState({ showEditContentPopUp: false })}
          onCancel={() => this.setState({ showEditContentPopUp: false })}
        />

        {/* For the case when less than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.lessQuestionsGenerated}
          showCloseButton
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Only {this.state.totalQuestionsFormed} questions generated. Add
                more content to generate the required number of questions.{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ lessQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ lessQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ lessQuestionsGenerated: false })}
          onCancel={() => this.setState({ lessQuestionsGenerated: false })}
        />

        {/* For the case when more than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.moreQuestionsGenerated}
          showConfirm={false}
          showCancel
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Question generated are more than requested{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ moreQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ moreQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ moreQuestionsGenerated: false })}
          onCancel={() => this.setState({ moreQuestionsGenerated: false })}
        />

        <SweetAlert
          show={this.state.showAddOn}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Want this feature?"
          confirmBtnText={`Proceed`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showAddOn: false })}
          onOutsideClick={() => this.setState({ showAddOn: false })}
          onCancel={() => this.setState({ showAddOn: false })}
        >
          <div style={{ padding: "20px" }}>
            <h4>Upgrade to access Premium add ons</h4>
            <div className="login--form">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: this.state.addOnText }}
              ></div>

              <div>
                <button
                  onClick={() => this.displayStripe(this.state.activatePlan)}
                  type="button"
                  role="button"
                  className="preview--paper--btn"
                  style={{
                    width: "max-content",
                  }}
                  disabled={this.state.showLoader}
                >
                  {this.state.addOnCTAText}
                </button>
              </div>
              {this.state.activatePlan.plan_name === "Free" && (
                <Link to="/plans" className="ff-tdd">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.freeAddOnSubText,
                    }}
                  ></span>
                </Link>
              )}
              {this.state.showLoader ? (
                <div className="spinner-border mt-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>

        {/* <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert> */}

        <TakeAssessment
          content_id={this.state.currentContentId}
          topic_name={this.state.currentTopicName}
          show={this.state.showDurationAlert}
          callBack={this.callbackFunc}
          history={this.props.history}
        />

        <ReferPopup />
        {this.state.showTopUp && (
          <QuestionPaperTopUp
            data={
              this.props.addOnsList["Question-Paper"][
                this.props.user.plan_details.plan_name
              ]
            }
            action={this.showTopUp}
            callBack={this.questionPaperTopUPCallBack}
          ></QuestionPaperTopUp>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
    selectedPurPlan: state.generalActions.plan,
  };
};

export default connect(mapStateToProps)(QuestionPaperGenerate);

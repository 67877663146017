import React, { Component } from "react";
import ReactSelect, { components } from "react-select";
import { connect } from "react-redux";
import {
  setQuesValueType,
  setQuesValueCount,
  setInitialQues,
} from "../../store/reducers/generalActions";
import _ from "lodash";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class CustomQuestions extends Component {
  state = {
    ques_type: [],
    ques_count: null,
    finalques_type: [],
    ques_type_option: [
      { value: "1", label: "Easy MCQ" },
      { value: "2", label: "Medium MCQ" },
      { value: "3", label: "Hard MCQ" },
      { value: "4", label: "True/False" },
      { value: "5", label: "Descriptive" },
      { value: "6", label: "Fillups" },
    ],
    ques_type_option_blooms: [
      { value: "1", label: "Knowledge" },
      { value: "2", label: "Comprehension" },
      { value: "3", label: "Application" },
      { value: "4", label: "Analysis" },
      { value: "5", label: "Evaluation" },
      { value: "6", label: "Synthesis" },
    ],

    customStyles: {
      option: (provided, state) => ({
        ...provided,
      }),

      container: (base) => ({
        ...base,

        border: this.state.occupation_message ? "1px solid red" : "",
        borderRadius: 5,
      }),

      // control: () => ({
      //   // none of react-select's styles are passed to <Control />
      //   border:"1px solid red"
      // }),
      menu: (provided, state) => ({
        ...provided,
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: 13,
        color: "#8898aa",
      }),
    },
    // disableEnterQuestions: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.updateQuesTypeOptions();
    }
  }

  updateQuesTypeOptions() {
    const { ques_type_option, ques_type_option_blooms } = this.state;

    // if (!_.isEmpty(this.props.user)) {
    //   if (!this.props.user.plan_details.select_question_type) {
    //     this.setState({ disableEnterQuestions: "sm-disabled" });
    //   }
    // }

    const updatedOptions = ques_type_option.map((option, index) => {
      const isDisabled =
        !_.isEmpty(this.props.user) &&
        !this.props.user.plan_details.select_question_type;

      // if (isDisabled) this.setState({ disableEnterQuestions: "sm-disabled" });
      return { ...option, isDisabled };
    });

    const updatedBloomOptions = ques_type_option_blooms.map(
      (hotOptions, index) => {
        const isDisabled =
          !_.isEmpty(this.props.user) &&
          !this.props.user.plan_details.select_question_type;

        return { ...hotOptions, isDisabled };
      }
    );
    this.setState({ ques_type_option: updatedOptions });
    this.setState({ ques_type_option_blooms: updatedBloomOptions });
  }

  componentDidMount = () => {
    this.updateQuesTypeOptions();
  };

  setQuesType = (ques_type) => {
    // this.props.setQuesType(ques_type)
    this.setState({ ques_type: ques_type });
    let finalques_type = ques_type.map((el) => el.value);
    finalques_type = finalques_type.join(",");
    this.props.dispatch(setQuesValueType(finalques_type));
  };

  handleQuesCount = (e) => {
    e.preventDefault();
    const regex = /^[1-9][0-9]*$/;
    const value = e.target.value;
    if (value.length < 1) {
      this.setState({ ques_count: "" });
      this.props.dispatch(setQuesValueCount(""));
    } else if (value == 0) {
      // console.log("ran")
      // this.setState({ ques_count: null})
      //   const reg = /^[1-9]/;
      //   if (reg.test(value)) {
      //     this.setState({ ques_count: value });
      //   } else {
      //     this.setState({ ques_count: null})
      //   }
    } else if (value.length > 3) {
      //do nothing
    } else {
      if (regex.test(value)) {
        this.setState({ ques_count: value });
        this.props.dispatch(setQuesValueCount(value));
      }
    }
  };

  render() {
    let is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;
    return (
      <>
        <div
          style={{ gap: "20px" }}
          className="d-flex justify-content-center mb-3"
        >
          <div className="" style={{ width: "60%" }}>
            <label
              for="selectques_type"
              className="form-label d-flex align-items-center"
              style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
            >
              {" "}
              Select Question Types{" "}
              {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  alt="icon"
                  width="30"
                  height="30"
                  style={{
                    position: "absolute",
                    marginLeft: "168px",
                    marginTop: "-15px",
                  }}
                />
              )}
              {/* <span style={{ height: "1.2rem" }} className="badge badge-info">
              BETA
            </span> */}
            </label>
            <div className=" text-left">
              <ReactSelect
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                maxMenuHeight={200}
                components={{
                  Option,
                }}
                isClearable
                options={
                  this.props.isblooms
                    ? this.state.ques_type_option_blooms
                    : this.state.ques_type_option
                }
                type="text"
                placeholder={
                  this.state.ques_type.length > 0
                    ? this.state.ques_type
                    : "Choose question types."
                }
                id="selectques_type"
                // onInputChange={(e) => this.handleCompanyinput(e)}
                onChange={this.setQuesType}
                value={this.state.ques_type}
                styles={this.state.customStyles}
              />
            </div>
          </div>
          <div className="position-relative" style={{ width: "40%" }}>
            <label
              for="selectques_type"
              className="form-label d-flex align-items-center"
              style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
            >
              {" "}
              Select Number of Questions{" "}
              {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  alt="icon"
                  width="30"
                  height="30"
                  style={{
                    position: "absolute",
                    marginLeft: "214px",
                    marginTop: "-15px",
                  }}
                />
              )}
              <span style={{ height: "1.2rem" }} className="badge badge-info">
                BETA
              </span>
            </label>
            <input
              className={`custom-login-field--inputs form-control px-2 ${
                !_.isEmpty(this.props.user) &&
                !this.props.user.plan_details.enter_number_of_question
                  ? "sm-disabled"
                  : ""
              }`}
              type="text"
              name="ques_count"
              value={this.state.ques_count}
              onChange={this.handleQuesCount}
              id="ques_count"
              aria-describedby="textHelp"
              placeholder="e.g. 30"
              style={{ height: "2.4rem", background: "none" }}
              disabled={
                !_.isEmpty(this.props.user) &&
                !this.props.user.plan_details.enter_number_of_question
              }
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch, // ← Add this
});

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomQuestions);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { btUpdateToken, inviteEnterpriseMembers } from "./../services";
import {
  showNotificationBar,
  closeNotificationBar,
} from "./../store/reducers/generalActions";
import NotificationBar from "./common/notificationBar";
import SweetAlert from "react-bootstrap-sweetalert";
import Salebar from "./sales";
import MSG, { notifySuccess, notifyError } from "./../utils/Helper";
import { startLoader, stopLoader } from "./../store/reducers/generalActions";
import InviteMembers from "./inviteEnterpriseMember";
import _ from "lodash";

class Header extends Component {
  state = {
    refreshing: true,
    showAlert: false,
    showReferral: false,
    referralEmail: "",
    referralMessage: "",
    showBadge: true,
    showBar: false,
    showInvitePopUp: false,
  };

  componentDidMount() {
    this.setState({
      refreshing: false,
      showReferral: false,
    });

    // this.timer = setInterval(() => {
    //   // Set the date we're counting down to(Sale end date)
    //   var countDownDate = new Date("Dec 12, 2022 12:00:00").getTime();
    //   // Convert local time to IST
    //   countDownDate = countDownDate.toLocaleString("en-US", {
    //     timeZone: "Asia/Kolkata",
    //   });

    //   // get today's date
    //   var today = new Date().getTime();
    //   today = today.toLocaleString("en-US", {
    //     timeZone: "Asia/Kolkata",
    //   });

    //   if (today >= countDownDate) this.setState({ showBar: true });
    // }, 1000);
  }

  handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
    // this.props.history.replace("/login");
  };

  handleNotification = () => {
    if (this.props.notificationBarShow) {
      this.props.dispatch(closeNotificationBar());
    } else {
      this.props.dispatch(showNotificationBar());
      this.setState({ showBadge: false });
    }
  };

  // handleReferralToOpen = () => {
  //   this.setState({
  //     showReferral: true,
  //   });
  // };

  // handleReferralToClose = () => {
  //   this.setState({
  //     showReferral: false,
  //   });
  // };

  showHeaderTextPlan = () => {
    if (this.props.user.plan_details && this.props.user.role != 2) {
      var planButtonText = "Upgrade Plan";

      if (
        this.props.user.plan_details.plan_name == "Yearly" ||
        this.props.user.role === 1
      ) {
        var planButtonText = "View Plan";
      }
      return (
        <React.Fragment>
          <React.Fragment>
            <li className="nav-item fl-center">
              {/* Get the most of PrepAI with{" "}
                <Link className="nav-link text-success-prep ml-1" to="/plans">
                  Premium Plans
                </Link> */}
              <Link className="btn upgrade-plan-btn" to="/plans">
                {planButtonText}
              </Link>
            </li>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  showHeaderApiAccess = () => {
    if (this.props.user.plan_details && this.props.user.role === 0) {
      var planButtonText = "Get API Access";
      return (
        <React.Fragment>
          <React.Fragment>
            <li className="nav-item fl-center">
              <a
                href="https://rapidapi.com/DataToBiz/api/prepai-generate-questions"
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-success"
              >
                {planButtonText}
              </a>
            </li>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  showHeaderTextFreePlan = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          {this.props.user.plan_details.plan_name == "Free" ? (
            <React.Fragment>
              <li
                className="nav-item"
                style={{ position: "absolute", top: 0, right: "10px" }}
              >
                <span className="btn btn-main btn-sm disabled">Free Plan</span>
              </li>
            </React.Fragment>
          ) : (
            <li
              className="nav-item"
              style={{ position: "absolute", top: 0, right: "10px" }}
            >
              <span
                className={`btn btn-${this.props.user.plan_details.plan_name} btn-sm`}
              >
                {this.props.user.plan_details.plan_name} Plan
              </span>
            </li>
          )}
        </React.Fragment>
      );
    }
  };

  showUserPlan = () => {
    if (
      this.props.user.plan_details &&
      !this.props.user.plan_details.is_plan_expired
    ) {
      return (
        <React.Fragment>
          {this.props.user.plan_details.plan_name == "Free" ? (
            <span className="basic">Basic</span>
          ) : (
            <span
              className={
                this.props.user.role === 2 ? "enterprise-member" : "premium"
              }
              style={
                this.props.user.plan_details.free_trial
                  ? { marginLeft: "31px", fontSize: "x-small" }
                  : {}
              }
            >
              {this.props.user.role === 2
                ? "Enterprise Member"
                : this.props.user.role === 1
                ? "Enterprise"
                : this.props.user.plan_details.free_trial
                ? "Premium (Limited)"
                : "Premium"}
            </span>
          )}
        </React.Fragment>
      );
    }
  };

  redirectToBloom = async () => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await btUpdateToken();
      if (res.success == true) {
        window.open(
          `${process.env.REACT_APP_HOTSPREPAI_APP_URL}/sso?token=${res.response.token}`
        );
      } else {
        notifyError("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  inviteEnterpriseMembers = async (Emails) => {
    this.props.dispatch(startLoader());
    try {
      const { data: response } = await inviteEnterpriseMembers({
        inviteEmails: Emails,
      });

      if (response.success == true) {
        // this.props.dispatch(fetchUser());
        notifySuccess(response.message);

        this.setState({
          showInvitePopUp: false,
        });
      } else {
        notifyError(response.message);
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleInviteCallBack = (data) => {
    if (data.hidePopUp) {
      this.setState({
        showInvitePopUp: !data.hidePopUp,
      });
      return;
    }

    // let inviteEmails = data.inviteMembers;
    // if (!_.isEmpty(inviteEmails)) {
    //   this.inviteEnterpriseMembers(inviteEmails);
    //   return;
    // }
  };

  render() {
    let pathname = this.props.location.pathname;
    const paths = ["/verification-required", "/referral-code"];
    return (
      <React.Fragment>
        {this.state.showBar && (
          <div className="row col-md-12 countdwn-home">
            <div
              className="col-md-8"
              style={{ justifyContent: "end", display: "flex" }}
            >
              <p className="countdown_msg_bt">
                Bloom’s Taxonomy update is live!
              </p>
              <span
                className="learnmorebtn-bt"
                onClick={this.redirectToBloom}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Try it now!{" "}
              </span>
            </div>
            {/* <div className="col-md-4 countdown_msg_bt">
              <span
                onClick={(e) => {
                  this.setState({ showBar: false });
                }}
                style={{ padding: "15px", cursor: "pointer" }}
              >
                Dismiss
              </span>
            </div> */}
          </div>
        )}
        {/*<Tooltip title="Coming Soon" id="global" /> */}
        <div
          style={paths.includes(pathname) ? { display: "none" } : { gap: "1" }}
          className="header-parent"
        >
          <Salebar></Salebar>

          <div className="custom-navbar">
            <nav className="navbar navbar-expand-md">
              <div className="container" style={{ maxWidth: "100vw" }}>
                <div className="style-logo">
                  <Link className="navbar-brand" to="/generate-questions">
                    <img
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                      alt="prepAi-logo"
                      style={{ width: "120px" }}
                    />
                  </Link>
                </div>
                {this.showUserPlan()}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse custom-navlinks"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto">
                    {this.props.user.role === 1 && (
                      <li className="nav-item dropdown fl-center">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span style={{ paddingRight: "3px" }}>
                            Enterprise
                          </span>
                          <span className="badge e-badge-info">BETA</span>
                          <span className="ml-1 arr-stroke">
                            <svg
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                            >
                              <path
                                d="M14 1L7.5 8L1 1"
                                stroke="#526892"
                                strokeLinecap="square"
                              />
                            </svg>
                          </span>
                        </a>
                        <div
                          className="dropdown-menu navdrop--menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link className="dropdown-item" to="/dashboard">
                            Dashboard/Report
                          </Link>
                          <Link className="dropdown-item" to="/user-management">
                            Manage Users
                          </Link>
                          {/* <Link
                            // to=""
                            className="dropdown-item"
                            onClick={(e) => (
                              e.preventDefault(),
                              this.setState({ showInvitePopUp: true })
                            )}
                            // style={{ pointer: "cursor" }}
                          >
                            Invite Member{" "}
                          </Link> */}
                        </div>
                      </li>
                    )}

                    {!_.isEmpty(this.props.user) &&
                      this.props.user.plan_details.is_new_pricing === 1 && (
                        <li className="nav-item fl-center">
                          <span
                            className="nav-link"
                            style={{ fontSize: "14px" }}
                          >
                            <b> Remaining Question Papers: </b>{" "}
                            <span style={{ color: "red" }}>
                              {this.props.user.remaining_question_papers}
                            </span>
                          </span>
                        </li>
                      )}

                    {this.showHeaderTextPlan()}
                    {/* {this.showHeaderApiAccess()} */}
                    {/* {this.props.user.role === 0 &&
                      this.props.user.role !== undefined && (
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="/refer-prepai"
                            style={{ marginTop: "-8px" }}
                          >
                            <img
                              src={`${process.env.REACT_APP_PROJECT_URL}/img/free-morph-outline.gif`}
                              width="60"
                              height="60"
                              alt="gif_loading"
                            ></img>
                          </Link>
                        </li>
                      )} */}
                    {this.props.user.email && (
                      <li className="nav-item fl-center">
                        {![
                          "/question-paper",
                          "/my-assessments",
                          "/my-tests",
                        ].includes(this.props.location.pathname) ? (
                          <Link className="nav-link" to="/question-paper">
                            My Question Papers
                          </Link>
                        ) : (
                          <Link className="nav-link" to="/generate-questions">
                            Generate Question Papers
                          </Link>
                        )}
                      </li>
                    )}
                    <li className="nav-item fl-center">
                      <div className="notify-tool-tip">
                        <i
                          style={{ marginTop: "6px" }}
                          className="fas fa-info-circle instructions"
                          onClick={() => this.setState({ showAlert: true })}
                        ></i>
                        <span className="hoverText">Instructions</span>
                      </div>
                    </li>
                    <li className="nav-item fl-center">
                      <div className="notify-tool-tip" id="notify-bell">
                        {/* {this.state.showBadge && <span className="notify-new-icon"> New </span>} */}
                        <i
                          style={{ marginTop: "5px" }}
                          className="far fa-bell notification"
                          onClick={this.handleNotification}
                        ></i>
                        {/* <span className="hoverText">Notifications</span> */}
                      </div>
                      {this.props.user.new_notify_count > 0 && (
                        <span
                          style={{ marginTop: "18px", marginLeft: "14px" }}
                          className="position-absolute rounded-pill badge style-badge text-white"
                        >
                          {this.props.user.new_notify_count}
                        </span>
                      )}
                    </li>
                    <li className="nav-item dropdown fl-center">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="dinline-block mr-2">
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.4118 5.07588C11.4118 7.52341 9.44941 9.48593 7.00016 9.48593C4.55174 9.48593 2.5885 7.52341 2.5885 5.07588C2.5885 2.62836 4.55174 0.666672 7.00016 0.666672C9.44941 0.666672 11.4118 2.62836 11.4118 5.07588ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2325 13.6668 14.4991C13.6668 16.7667 10.5957 17.3333 7.00016 17.3333Z"
                              fill="#526892"
                            />
                          </svg>
                        </span>
                        {this.props.user.name}
                        <span className="ml-1 arr-stroke">
                          <svg
                            width="15"
                            height="9"
                            viewBox="0 0 15 9"
                            fill="none"
                          >
                            <path
                              d="M14 1L7.5 8L1 1"
                              stroke="#526892"
                              strokeLinecap="square"
                            />
                          </svg>
                        </span>
                      </a>
                      <div
                        className="dropdown-menu navdrop--menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {this.props.user.role !== 2 &&
                          this.props.user.role !== undefined && (
                            <Link className="dropdown-item" to="/my-account">
                              My Account
                            </Link>
                          )}
                        <Link className="dropdown-item" to="/edit-profile">
                          Edit Profile
                        </Link>
                        <Link className="dropdown-item" to="/change-password">
                          Change Password
                        </Link>
                        {this.props.user.role === 0 &&
                          this.props.user.plan_details.is_new_pricing === 1 && (
                            <Link className="dropdown-item" to="/mydashboard">
                              Dashboard/Report
                            </Link>
                          )}
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={this.handleLogout}
                        >
                          Log out
                        </Link>
                      </div>
                    </li>
                    {/*{this.showHeaderTextFreePlan()}*/}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          {this.props.notificationBarShow && <NotificationBar />}
        </div>
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showAlert}
          showConfirm
          title={
            <div className="swal-title">
              <h2>Please Read Carefully</h2>
              <hr />
              <ol>
                <li className="instruction-points">
                  PrepAI now supports Subjective as well as Non-Subjective
                  content like Mathematical Equations, Chemical Reactions,
                  Numericals, etc. as well.
                </li>
                <li className="instruction-points">
                  PrepAI generates quality questions on quality content. It may
                  not produce accurate results in cases where content is not
                  structured, or contains tabular data, diagrams, etc.
                </li>
                <li className="instruction-points">
                  PrepAI is available for content in English Language.
                </li>
              </ol>
            </div>
          }
          confirmBtnText="OK"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showAlert: false })}
          onOutsideClick={() => this.setState({ showAlert: false })}
          onConfirm={() => this.setState({ showAlert: false })}
          onCancel={() => this.setState({ showAlert: false })}
        />

        {this.props.notificationBarShow && <NotificationBar />}

        {this.state.showInvitePopUp && (
          <InviteMembers callBack={this.handleInviteCallBack} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationBarShow: state.generalActions.notificationBarShow,
    user: state.auth.user,
  };
};
export default withRouter(connect(mapStateToProps)(Header));

import { Component } from "react";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  startLoader,
  stopLoader,
  setSelectedPlan,
} from "./../../store/reducers/generalActions";
import MSG, {
  notifySuccess,
  notifyError,
  notifyInfo,
  profession,
  findUsList,
} from "./../../utils/Helper";
import { purchaseAddOns } from "./../../services";

import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./AddOnsCheckoutForm";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class QuestionPaperTopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUP: props.action,
      showStripeForm: false,
      userInputValue: 1,
      stripePromise: null,
      stripeClientSecret: "",
      showStripeForm: false,
      stripeKey: process.env.REACT_APP_STRIPE_KEY,
    };
  }

  calculateCreditsAmt = (value, price) => {
    var val = parseFloat(value);
    if (Number.isNaN(val)) val = 0;
    var questionPaper = val;
    var amt = val * price;
    return [amt.toFixed(2), questionPaper];
  };

  showAmount = () => {
    var [amount, questionPaper] = this.calculateCreditsAmt(
      this.state.userInputValue,
      this.props.data.price
    );

    return (
      <div>
        <div style={{ fontSize: "17px", fontWeight: "500" }}>
          {/* <i className="fas fa-dollar-sign" style={{ marginRight: "5px" }}></i> */}
          <p>
            {questionPaper} Question {questionPaper > 1 ? "Papers" : "Paper"}
          </p>
        </div>
        <div style={{ display: "-webkit-inline-box" }}>
          <p style={{ fontWeight: "500", marginRight: "3px" }}>
            <b>{this.props.data.currency_symbol}</b>
          </p>
          <p>{amount}</p>
        </div>
      </div>
    );
  };

  displayStripe = async (plan) => {
    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    var [amount, questionPaper] = this.calculateCreditsAmt(
      this.state.userInputValue,
      this.props.data.price
    );

    try {
      let req = {
        add_on_id: plan.plan_id,
        top_up: 1,
        amount: amount,
        question_paper: questionPaper,
      };

      const { data: res } = await purchaseAddOns(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
          // price_usd: plan.price,
          // plan_name: plan.plan_name,
          // currency:
          //   this.state.plans["Monthly"][this.state.questionPaperCountSolo][
          //     "currency"
          //   ],
          // team_size: this.state.team_size,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showPopUP: false,
          });
          // this.props.dispatch(
          //   showReferral({
          //     title: "Want to extend your plan for free?",
          //     content:
          //       "Invite your friends and get 7 days of Premium Plan added",
          //   })
          // );
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  handleClose = () => {
    this.setState({
      showPopUP: false,
    });
    this.props.callBack({ hidePopUp: true });
  };

  render() {
    return (
      <>
        <SweetAlert
          show={this.state.showPopUP}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Question Paper Top Up"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.handleClose()}
          onOutsideClick={() => this.handleClose()}
          onCancel={() => this.handleClose()}
        >
          <div>
            <h3>Question Paper Top Up</h3>
            <div class="table" style={{ padding: "15px 30px" }}>
              {this.showAmount()}

              <i
                class="fa fa-minus"
                aria-hidden="true"
                style={{
                  margin: "10px",
                  cursor: "pointer",
                  pointerEvents:
                    this.state.userInputValue <= 1 ||
                    this.state.userInputValue === ""
                      ? "none"
                      : "",
                }}
                onClick={() => {
                  this.setState({
                    userInputValue: parseFloat(this.state.userInputValue) - 1,
                  });
                }}
              ></i>
              <input
                value={this.state.userInputValue}
                className="credit-box"
                pattern="[0-9]*"
                onChange={(e) => {
                  var value = e.target.validity.valid
                    ? e.target.value
                    : this.state.userInputValue;
                  if (value !== "") {
                    value = parseFloat(value);
                  }
                  this.setState({ userInputValue: value });
                }}
              ></input>
              <i
                class="fa fa-plus"
                aria-hidden="true"
                style={{ margin: "10px", cursor: "pointer" }}
                onClick={() => {
                  var value = this.state.userInputValue;
                  if (value === "") {
                    value = 0;
                  }
                  this.setState({
                    userInputValue: value + 1,
                  });
                }}
              ></i>
            </div>
            <span class="monthlyplanpricee">
              <a
                href="#"
                style={{
                  pointerEvents:
                    this.state.userInputValue === 0 ||
                    this.state.userInputValue === ""
                      ? "none"
                      : "",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.displayStripe(this.props.data);
                }}
              >
                Buy
              </a>
            </span>
            <br />
            {this.state.showLoader ? (
              <div className="spinner-border mt-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(QuestionPaperTopUp));

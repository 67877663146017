import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";

class ToggleForGQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonType: props.tab,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(buttonType) {
    this.setState({ buttonType });
  }

  render() {
    const { buttonType } = this.state;
    const isHotsDisabled =
      !_.isEmpty(this.props.user) && !this.props.user.plan_details.hots_mode;
    const isSpecialistDisabled =
      !_.isEmpty(this.props.user) && !this.props.user.plan_details.specialist;
    const is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;
    const active_plan_name =
      !_.isEmpty(this.props.user) && this.props.user.plan_details.plan_name;

    return (
      <div
        className="mt-3 d-flex align-items-center"
        style={{
          position: "absolute",
          top: "9rem",
          left: "11.2rem",
          gap: "1rem",
        }}
      >
        <label
          htmlFor="selectques_type"
          className="form-label d-flex text-white"
          style={{ fontWeight: 500, marginTop: "10px" }}
        >
          {" "}
          Choose Your Mode{" "}
        </label>

        <div className="easy-med-hard shadow-none bg-white">
          <Link
            className={`btn px-custom py-2 ${
              buttonType === "Standard" &&
              "active-diff-btn border-radius-left-diff"
            }`}
            onClick={() => this.handleButtonClick("Standard")}
            to="/generate-questions"
          >
            Standard
          </Link>
          <span>|</span>
          {isHotsDisabled ? (
            <span
              className={`btn px-custom py-2 toggle-gq`}
              disabled={isHotsDisabled}
            >
              HOTS
              {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  alt="icon"
                  width="30"
                  height="30"
                  style={{ position: "absolute", top: "-5px", right: "55px" }}
                />
              )}
              {/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
            </span>
          ) : (
            <Link
              className={`btn px-custom py-2 toggle-gq ${
                buttonType === "HOTS" && "active-diff-btn"
              } `}
              onClick={() => this.handleButtonClick("HOTS")}
              to="/generate-questions-blooms"
            >
              HOTS
              {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  alt="icon"
                  width="30"
                  height="30"
                  style={{ position: "absolute", top: "-5px", right: "55px" }}
                />
              )}
              {/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
            </Link>
          )}
          {buttonType === "HOTS" && !is_new_pricing && (
            <div
              className="notify-tool-tip"
              style={{
                position: "absolute",
                right: "16rem",
                top: "9px",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{
                  fontSize: "16px",
                  color: "#08164e",
                  cursor: "pointer",
                }}
              ></i>
              {active_plan_name == "Free" ? (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  You've{" "}
                  {this.props.user.credits <= 0
                    ? "0 free credits"
                    : this.props.user.credits}{" "}
                  free credits worth{" "}
                  {this.props.user.credits > 0 &&
                    `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                  available in your account.
                  <br />
                  <span style={{ color: "white" }}>
                    Upgrade to PrepAI Premium
                  </span>{" "}
                  to get 54 free credits worth $27
                </span>
              ) : (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  Credits Available:{" "}
                  {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                </span>
              )}
            </div>
          )}
          <span>|</span>
          {isSpecialistDisabled ? (
            <span
              className={`btn px-custom py-2 toggle-gq`}
              disabled={isSpecialistDisabled}
            >
              Specialist
              {is_new_pricing === 1 && (
                <img
                  src="img/crown.jpg"
                  alt="icon"
                  width="30"
                  height="30"
                  style={{ position: "absolute", top: "-2px", right: "35px" }}
                />
              )}
              {/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
            </span>
          ) : (
            <>
              <Link
                className={`btn px-custom py-2 toggle-gq border-radius-right-diff ${
                  buttonType === "Special" && "active-diff-btn"
                }`}
                onClick={() => this.handleButtonClick("Special")}
                to="/subject-expertise-questions"
              >
                Specialist
                {is_new_pricing === 1 && (
                  <img
                    src="img/crown.jpg"
                    alt="icon"
                    width="30"
                    height="30"
                    style={{ position: "absolute", top: "-2px", right: "35px" }}
                  />
                )}
                {/* <span className="badge badge-info beta-tag-toggle-gq">BETA</span> */}
              </Link>
            </>
          )}
          {buttonType === "Special" && !is_new_pricing && (
            <div
              className="notify-tool-tip"
              style={{
                position: "absolute",
                right: "2rem",
                top: "9px",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{
                  fontSize: "16px",
                  color: "#08164e",
                  cursor: "pointer",
                }}
              ></i>
              {active_plan_name == "Free" ? (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  You've{" "}
                  {this.props.user.credits <= 0
                    ? "0 free credits"
                    : this.props.user.credits}{" "}
                  free credits worth{" "}
                  {this.props.user.credits > 0 &&
                    `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                  available in your account.
                  <br />
                  <span style={{ color: "white" }}>
                    Upgrade to PrepAI Premium
                  </span>{" "}
                  to get 54 free credits worth $27
                </span>
              ) : (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  Credits Available:{" "}
                  {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ToggleForGQ);
